import React from 'react';
import utils from "../../utils";
import toraPortalApiService from "../../services/ToraPortalApiService";
import {Card, CardBody, CardHeader} from "reactstrap";
import Loadable from "react-loading-overlay";
import ExecutionReportsTable from './ExecutionsReportsTable';
import ClientsSidebar from "../../components/ClientsSidebar/ClientsSidebar";
import {Redirect} from "react-router-dom";


class ExecutionsReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: utils.getUserData(),
            loadingData: false,
            loadingText: '',
            clientList: [],
            isDropdownOpen: false,
            selectedClient: 'View As',
            clientData: [],
            funds: [],
            years: [],
            months: [],
            reports: [],
            currentYear: undefined,
            currentMonth: undefined,
            yearVolumesReport: undefined,
            MonthVolumeReport: undefined,
            MonthConsolidateReport: undefined,
            currentFund: undefined,
        };
    };

    resetClientData() {
        this.setState({
            funds: [],
            years: [],
            months: [],
            reports: [],
            currentYear: undefined,
            currentMonth: undefined,
            currentFund: undefined,
        })
    }


    componentDidMount() {
        this.setState({
            userIsAdmin: utils.isUserAdmin(),
            loadingData: true
        }, () => {
            this.getClient();
        });
    };

    getClient() {
        let {userIsAdmin} = this.state;

        if (userIsAdmin) {
            let clientName = JSON.parse(localStorage.getItem('activeClientName'));
            this.setState({
                selectedClient: clientName,
            }, () => {
                this.getClientData(clientName)
            });

        } else {
            let {user} = this.state;
            if (user) {
                this.setState({selectedClient: user.client}, () => {
                    this.getClientData(this.state.selectedClient);
                })
            }
        }
    };


    getClientData(clientName) {
        this.resetClientData();

        toraPortalApiService.getAvailableReportDates(clientName, utils.reportsTypeFolderNameMap['executions']).then((dates) => {
            if (dates.clientData.length === 0) {
                this.setState({
                    funds: [],
                    loadingData: false
                });
                return false;
            }

            let funds = [];

            dates.clientData.forEach((obj, index) => {
                if (obj.fundName) {
                    funds.push(obj.fundName)
                }
            });

            this.setState({
                clientData: dates.clientData,
                funds: funds,
            }, () => {
                this.onFundClick(funds[0]);
            });
        }).catch((e) => {
            this.setState({loadingData: false});
        });
    };

    onClientChange = () => {
        this.setState({loadingData: true});
        let clientName = JSON.parse(localStorage.getItem('activeClientName'));
        this.setState({
            selectedClient: clientName,
        }, () => {
            this.getClientData(clientName)
        })
    };


    getLatestUserReports() {
        let {years} = this.state;

        let latestYear = years[years.length - 1];
        this.setState({currentYear: latestYear}, () => {
            this.getYearVolumesReport();
            this.getClientAvailableMonths(latestYear).then((months) => {
                let latestMonth = months[months.length - 1];
                this.onMonthClick(latestMonth);
            })
        });
    };

    getClientAvailableYears(currentFund) {
        //Get Years for selected client
        let {clientData} = this.state,
            years = [];

        clientData.forEach((fundObj, index) => {
            if (fundObj.fundName === currentFund) {
                fundObj.availableReports.forEach((report) => {
                    if (report.year) {
                        years.push(report.year);
                    }
                })
            }
        });

        this.setState({years: years.sort((a, b) => a - b)}, () => {
            this.getLatestUserReports();
        })
    };

    getClientAvailableMonths(year) {
        //Get Months for client
        let {clientData, currentFund} = this.state;
        let months = [];

        clientData.forEach((fundObj) => {
            if (fundObj.fundName === currentFund) {
                fundObj.availableReports.forEach((rep) => {
                    if (rep.year === year) {
                        months = rep.availableMonths.sort((a, b) => {
                            return a - b;
                        });
                    }
                })
            }
        });

        this.setState({months}, () => {
            this.onMonthClick(months[months.length - 1])
        });
        return Promise.resolve(months);
    };


    onFundClick = (currentFund) => {
        this.setState({currentFund}, () => {
            this.getClientAvailableYears(currentFund)
        });
    };

    getYearVolumesReport() {
        const {currentFund, currentYear} = this.state;
        toraPortalApiService.getAvailableNonDailyReports(currentFund, currentYear, '', utils.reportsTypeFolderNameMap['executions'], 'Vol')
                            .then(resp => {
                this.setState({yearVolumesReport: resp.length > 0 ? resp[0] : null})
            })
    };

    getMonthVolumeReport() {
        const {currentFund, currentYear, currentMonth} = this.state;
        toraPortalApiService.getAvailableNonDailyReports(currentFund, currentYear, currentMonth, utils.reportsTypeFolderNameMap['executions'], 'Vol')
                            .then(resp => {
                this.setState({MonthVolumeReport: resp.length > 0 ? resp[0] : null})
            })
    }

    getMonthConsolidateReport() {
        const {currentFund, currentYear, currentMonth} = this.state;
        toraPortalApiService.getAvailableNonDailyReports(currentFund, currentYear, currentMonth, utils.reportsTypeFolderNameMap['executions'], 'Consolidate')
                            .then(resp => {
                this.setState({MonthConsolidateReport: resp.length > 0 ? resp[0] : null})
            })
    };

    onYearClick = (currentYear) => {
        this.setState({currentYear}, () => {
            this.getYearVolumesReport();
            this.getClientAvailableMonths(currentYear).then((months) => {})
        });
    };

    onMonthClick = (currentMonth) => {
        this.setState({currentMonth}, () => {
            this.getClientReports();
            this.getMonthVolumeReport();
            this.getMonthConsolidateReport();
        });
    };

    getClientReports() {
        let {currentFund, currentYear, currentMonth} = this.state;
        //Request for reports
        toraPortalApiService.getAvailableReports(currentFund, currentYear, currentMonth, utils.reportsTypeFolderNameMap['executions']).then((data) => {
            data.sort((a, b) => (parseInt(a.date.split('-')[0], 10) > parseInt(b.date.split('-')[0], 10)) ? 1 : -1);
            this.setState({
                reports: this.getProcessedReports(data),
                loadingData: false
            });
        });
    };

    getProcessedReports(data) {
        const map = new Map();
        data.forEach((report) => {
            if (!map.get(report.date)) {
                map.set(report.date, {date: report.date});
            }
            let obj = map.get(report.date);

            if (report.name.startsWith('Consolidate_')) {
                obj['consolidate'] = {label: report.name, downloadURL: report.downloadURL};
            } else if (report.name.startsWith('Vol_')) {
                obj['volumes'] = {label: report.name, downloadURL: report.downloadURL};
            } else if (report.name.toLowerCase().startsWith(`${this.state.currentFund.toLowerCase()}_`)) {
                obj['executions'] = {label: report.name, downloadURL: report.downloadURL};
            }
            map.set(report.date, obj);
        });

        return Array.from(map.values());
    }

    monthFormatter(month) {
        let monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return monthsOfYear[month - 1];
    };

    dayFormatter(day) {
        let formattedDay;
        switch (day) {
            case 1:
                formattedDay = '1st';
                break;
            case 2:
                formattedDay = '2nd';
                break;
            case 3:
                formattedDay = '3rd';
                break;
            default:
                formattedDay = `${day}th`;
        }
        return formattedDay;
    };

    render() {
        if (!utils.canViewExecutionsFiles() && !utils.isUserAdmin()) {
            return <Redirect to={'/resources'}/>
        }

        let {selectedClient, years, months, reports, currentYear, currentMonth, yearVolumesReport, MonthVolumeReport, MonthConsolidateReport, funds, currentFund, userIsAdmin} = this.state;

        return (
            <Loadable active={this.state.loadingData} spinner text={this.state.loadingText}>
                <div className="full-page-content small">

                    {
                        userIsAdmin &&
                        <ClientsSidebar onClientChange={this.onClientChange}/>
                    }
                    <div id={'content'} className="content" style={{'paddingTop': '35px'}}>
                        <Card className="card-stats card-raised executions-container">

                            <CardHeader>
                                <h4 className="titleContainerStyle"> {selectedClient} </h4>
                                <i className="now-ui-icons arrows-1_minimal-right"> </i>
                                <h4 className="titleContainerStyle"> Executions </h4>
                            </CardHeader>

                            <CardBody>
                                {funds.length ?
                                    <div className="container-for-dates">

                                        <div className="datesContainerStyle">
                                            {
                                                funds.map((fund) => {
                                                    return (
                                                        <div className="fundStyle"
                                                             onClick={e => this.onFundClick(fund)}>
                                                    <span
                                                        className={'date-text ' + (currentFund === fund ? 'active' : '')}> {fund} </span>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>

                                        {
                                            years.length ?
                                                <div className="datesContainerStyle">
                                                    <div className="dateTitleStyle">Years:</div>
                                                    {
                                                        years.map((year) => {
                                                            return (
                                                                <div className="dateStyle"
                                                                     onClick={e => this.onYearClick(year)}>
                                                    <span
                                                        className={'date-text ' + (currentYear === year ? 'active' : '')}> {year} </span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                                :
                                                ''
                                        }

                                        {
                                            months.length ?
                                                <div className="datesContainerStyle">
                                                    <div className="dateTitleStyle"> Months:</div>
                                                    {
                                                        months.map((month) => {
                                                            return (
                                                                <div className="dateStyle"
                                                                     onClick={e => this.onMonthClick(month)}>
                                                            <span
                                                                className={'date-text ' + (currentMonth === month ? 'active' : '')}> {this.monthFormatter(month)} </span>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                </div>
                                                :
                                                ''
                                        }
                                        {
                                            currentYear ?
                                                <div className="datesContainerStyle border-hr"
                                                     style={{paddingTop: '15px'}}>
                                                    <div className='full-reports'>
                                                        <span className="full-rep"> Year volumes report: </span>
                                                        {
                                                            currentMonth ?
                                                                <div>
                                                                    <span
                                                                        className="full-rep"> Month volume report: </span>
                                                                    <span className="full-rep"> Month consolidate report: </span>
                                                                </div>
                                                                :
                                                                ''
                                                        }

                                                    </div>

                                                    <div className="rep-file-container">
                                                        {
                                                            yearVolumesReport ?
                                                                <span className="rep-file"
                                                                      onClick={() => toraPortalApiService.downloadFile(yearVolumesReport.downloadURL)}>
                                                                <i style={{fontSize: '12px', paddingRight: '8px'}}
                                                                   className="now-ui-icons arrows-1_cloud-download-93"/>
                                                                    {yearVolumesReport.name}
                                                            </span>
                                                                :
                                                                <span className="rep-file-empty"> - </span>
                                                        }

                                                        {
                                                            currentMonth ?
                                                                <div>
                                                                    {
                                                                        MonthVolumeReport ?
                                                                            <span className="rep-file"
                                                                                  onClick={() => toraPortalApiService.downloadFile(MonthVolumeReport.downloadURL)}>
                                                                            <i style={{
                                                                                fontSize: '12px',
                                                                                paddingRight: '8px'
                                                                            }}
                                                                               className="now-ui-icons arrows-1_cloud-download-93"/>
                                                                                {MonthVolumeReport.name}
                                                                        </span>
                                                                            :
                                                                            <span className="rep-file-empty"> - </span>
                                                                    }
                                                                    {
                                                                        MonthConsolidateReport ?
                                                                            <span className="rep-file"
                                                                                  onClick={() => toraPortalApiService.downloadFile(MonthConsolidateReport.downloadURL)}>
                                                                            <i style={{
                                                                                fontSize: '12px',
                                                                                paddingRight: '8px'
                                                                            }}
                                                                               className="now-ui-icons arrows-1_cloud-download-93"/>
                                                                                {MonthConsolidateReport.name}
                                                                        </span>
                                                                            :
                                                                            <span className="rep-file-empty"> - </span>
                                                                    }
                                                                </div>
                                                                :
                                                                ''
                                                        }


                                                    </div>

                                                </div>
                                                :
                                                ""
                                        }

                                        {
                                            reports.length > 0 ?
                                                <div className='table-container' style={{width: '90%'}}>
                                                    <ExecutionReportsTable data={reports}
                                                                           year={currentYear}
                                                                           month={currentMonth}/>

                                                </div>
                                                :
                                                ''
                                        }
                                    </div>
                                    :
                                    <div>
                                        {
                                            <div>
                                                {
                                                    userIsAdmin ?
                                                        <div className='no-reports-message'> No reports available for
                                                            this client </div>
                                                        :
                                                        <div className='no-reports-message'> No reports available </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </div>
                    <div style={{clear: 'both'}}></div>
                </div>
            </Loadable>
        );
    }


}

export default ExecutionsReportsPage;
