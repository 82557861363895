import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {Footer} from '../../components'

import pagesRoutes, {mapPagesRoutesPathToName} from '../../routes/pages.jsx'
import Header from '../../components/Header/Header'
import toraPortalApiService from '../../services/ToraPortalApiService'
import utils from '../../utils'

class Onboarding extends React.Component {
  constructor() {
    super()
    this.state = {
      requiredDataLoaded: utils.isUserAdmin(),
    }
    this.requiredDataLoaded = this.requiredDataLoaded.bind(this)
  }

  componentWillMount() {
    if (!utils.isUserAdmin()) {
      toraPortalApiService.reloadUserDetails()
                          .then(() => this.requiredDataLoaded(true), () => this.requiredDataLoaded(false))
    }

    if (window.location && window.location.pathname in mapPagesRoutesPathToName) {
      toraPortalApiService.postWebPageAccessed(mapPagesRoutesPathToName[window.location.pathname],
                                               window.location.href)
    }
  }

  requiredDataLoaded(success) {
    this.setState({requiredDataLoaded: true})
  }

  render() {
    return (
        <div className="wrapper dashboard-layout">
          <div className="main-panel" ref="mainPanel">
            <Header {...this.props}/>
            <div className="wrapper wrapper-full-page" ref="fullPages">
              <div className="full-page section-image">
                <Switch>
                  {
                    pagesRoutes.map((prop, key) => {
                      if (prop.redirect) {
                        return (
                            <Redirect from={prop.path} to={prop.pathTo} key={key}/>
                        )
                      }
                      return (
                          <Route path={prop.path} component={prop.component} key={key}/>
                      )
                    })
                  }
                </Switch>
                <Footer/>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default Onboarding
