import React from 'react';
import PropTypes from 'prop-types';
import {
    Card, CardHeader, CardTitle, CardBody,
    Form, InputGroup, InputGroupAddon, Input, Alert
} from 'reactstrap';
import { Button } from '../../components';
import toraPortalApiService from '../../services/ToraPortalApiService';
import utils from '../../utils';

class ChangePassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            oldPassword:'',
            newPassword:'',
            confirmNewPassword:'',
            error:'',
            email: utils.getUserData().email,
            resetKey: this.props.resetKey
        };
        this.handleRequestPasswordReset = this.handleRequestPasswordReset.bind(this);
    }

    renderFormError() {
        return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
    }

    renderFormSuccess() {
        return ((this.state.success) ? <Alert color="success">{this.state.success} Please click <a href="login">here</a> to login with your new password.</Alert> : '')
    }

    handleChange = event => {
        this.setState({[event.target.name]:event.target.value});
    }

    handleRequestPasswordReset(event){
        event.preventDefault();
        // const userData = utils.getUserData();
        if(this.state.oldPassword.length === 0) {
          this.setState({error: 'Please input your old password.'})
          return;
        }

        if(this.state.newPassword.length < 8 || this.state.confirmNewPassword.length < 8){
          this.setState({error: 'Your password is not strong enough.'})
          return;
        }

        if(this.state.newPassword !== this.state.confirmNewPassword){
            this.setState({error: 'The two passwords fields must match!'})
            return;
        }

        if(this.state.newPassword === this.state.oldPassword){
            this.setState({ error: 'The new password must be different than the old one!' });
            return;
        }

        toraPortalApiService.initChangePassword(this.state.email, this.state.newPassword, this.state.oldPassword)
                            .then((response) => {
            this.setState({
              qrCodeImage: response.image,
              secretKey: response.secretKey
            });
            this.props.onPasswordResetListenerFn(this.state);
        })
                            .catch((error) => {
            this.setState({error: error.errorMessage})
        })
    }

    render(){
        return (
            <Card className="reset-password card-form">
                <CardHeader className="text-center">
                    <CardTitle>
                        <div>Reset your password</div>
                    </CardTitle>
                </CardHeader>
                <CardBody className="text-center">
                    <p>Change the account password and 2-Step authentification.</p>
                    <Form onSubmit={this.handleRequestPasswordReset}>
                    {this.renderFormError()}
                    {this.renderFormSuccess()}
                        <InputGroup className={(this.state.oldPasswordFocus ? "input-group-focus":"")}>
                            <InputGroupAddon ><i className="now-ui-icons ui-1_lock-circle-open"></i></InputGroupAddon>
                            <Input type="password" placeholder="Old Password..." name="oldPassword" onChange={this.handleChange} onFocus={(e) => this.setState({oldPasswordFocus: true})} onBlur={(e) => this.setState({oldPasswordFocus: false})} />
                        </InputGroup>
                        <InputGroup className={(this.state.newPasswordFocus ? "input-group-focus":"")}>
                            <InputGroupAddon ><i className="now-ui-icons ui-1_lock-circle-open"></i></InputGroupAddon>
                            <Input type="password" placeholder="New Password..." name="newPassword" onChange={this.handleChange} onFocus={(e) => this.setState({newPasswordFocus: true})} onBlur={(e) => this.setState({newPasswordFocus: false})} />
                        </InputGroup>
                        <InputGroup className={(this.state.confirmNewPasswordFocus ? "input-group-focus":"")}>
                            <InputGroupAddon ><i className="now-ui-icons ui-1_lock-circle-open"></i></InputGroupAddon>
                            <Input type="password" placeholder="Confirm new password..." name="confirmNewPassword" onChange={this.handleChange} onFocus={(e) => this.setState({confirmNewPasswordFocus: true})} onBlur={(e) => this.setState({confirmNewPasswordFocus: false})} />
                        </InputGroup>
                        <div className="instructions-container">
                            Hint: The password should be at least eight characters long. To make
                            it stronger, use upper and lower case letters, numbers and symbols like ! " ? $ % ^ ).
                        </div>
                        <Button className="btn btn-primary button-new-password" type="submit" lg>
                                Next step
                        </Button>
                    </Form>
                </CardBody>
            </Card>
        );
    }
}

ChangePassword.propTypes = {
  onPasswordResetListenerFn: PropTypes.func,
}

export default ChangePassword;
