import loginBackground from './bg_login.jpg'
import placeholder from './placeholder.jpg'
import lsegToraLogo from './logos_lseg-tora-logo-mix.svg'
import userAvatar from './web_icons_main_app-icon-default.png'
import reportsIcon from './navbar-icons/reports_icon.svg'
import dropdownArrowIcon from './navbar-icons/dropdown_arrow_icon.svg'
import resourcesIcon from './navbar-icons/resources_icon.svg'
import toolsIcon from './navbar-icons/tools_icon.svg'
import userIcon from './navbar-icons/user_icon.svg'
const toraStaffAvatar = userAvatar

export default {
  loginBackground,
  placeholder,
  lsegToraLogo,
  userAvatar,
  toraStaffAvatar,
  reportsIcon,
  dropdownArrowIcon,
  resourcesIcon,
  toolsIcon,
  userIcon
}
