import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import { Redirect } from 'react-router-dom'

import utils from '../../utils'
import Keycloak from '../../../src/keycloak'
import toraPortalApiService from '../../services/ToraPortalApiService'

export default class SSOLoginPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      isAuthenticated: false,
      isVerified: false,
      keyCloak: null,
      noPortalUser: false
    }
  }

  componentDidMount () {
    let keyCloak = this.state.keyCloak

    if (keyCloak === null) {
      keyCloak = new Keycloak('/keycloak.json')

      keyCloak.init({ onLoad: 'login-required', theme: 'tora' }).success(authenticated => {
        if (authenticated) {
          localStorage.setItem('token', 'SSO ' + keyCloak.token)
          localStorage.setItem('logoutUrl', keyCloak.createLogoutUrl({ redirectUri: window.location.protocol + '//' + window.location.host }))
          this.setState({isAuth: true})
          toraPortalApiService.publishSSOLoginCompleted().then(res =>{
            toraPortalApiService.reloadUserDetails().then(() => {
              this.setState({ keyCloak: keyCloak })
            })
          }).catch(err =>{
            this.setState({noPortalUser: true}, () => setTimeout(() => {
              utils.logoutUser()
            }, 10000))
          })
        }
      }).error(err =>{
        console.log("KEYCLOAK ERROR: ")
        console.log(err)
      })
    }
  }

  renderMessage = (message) => {
    return (
        <div className='sso-login-page'>
          <div className='full-page-content'>
            <div className='justify-content-center'>
              <Container>
                <Row className='justify-content-center'>
                  <Col lg={6} md={10} xs={12}>
                    <Card className='card-form'>
                      <CardBody>
                        <h3>{message}</h3>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
    )
  }

  render () {
    if (utils.userIsAuthenticated() || this.state.isAuth) {
      if (this.state.noPortalUser){
        return this.renderMessage("You don’t have access to this portal. Please Contact LSEG TORA Support!")
      }
      else if (utils.getUserData() && utils.isUserAccountManager()) {
        return <Redirect to={'/dashboard'}/>
      } else if (utils.getUserData()) {
        return <Redirect to={'/dashboard/resources'}/>
      }
    }

    return this.renderMessage("Please wait for authentication...")
  }
}
