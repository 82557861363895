import React from 'react';
import utils from "../../utils";
import toraPortalApiService from "../../services/ToraPortalApiService";
import {Card, CardBody, CardHeader} from "reactstrap";
import Loadable from "react-loading-overlay";
import LimitReportsTable from "./LimitReportsTable";
import ClientsSidebar from "../../components/ClientsSidebar/ClientsSidebar";
import {Redirect} from "react-router-dom";


class LimitReportsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: utils.getUserData(),
            loadingData: false,
            loadingText: '',
            clientList: [],
            isDropdownOpen: false,
            selectedClient: 'View As',
            clientData: [],
            funds: [],
            years: [],
            months: [],
            reports: [],
            currentYear: undefined,
            currentMonth: undefined,
            yearVolumesReport: undefined,
            MonthVolumeReport: undefined,
            MonthConsolidateReport: undefined,
            currentFund: undefined,
            limitDefinitionReport: undefined
        };

        this.downloadFile = this.downloadFile.bind(this);
    };

    resetClientData () {
        this.setState({
            funds: [],
            years: [],
            months: [],
            reports: [],
            currentYear: undefined,
            currentMonth: undefined,
            currentFund: undefined,
        })
    }


    componentDidMount() {
        this.setState({
            userIsAdmin: utils.isUserAdmin(),
            loadingData: true
        }, () => {
            this.getClient();
        });
    };

    getClient() {
        let {userIsAdmin} = this.state;

        if (userIsAdmin) {
            let clientName = JSON.parse(localStorage.getItem('activeClientName'));
            this.setState({
                selectedClient: clientName,
            }, () => {
                this.getClientData(clientName)
            });

        } else {
            let {user} = this.state;
            if (user) {
                this.setState({selectedClient: user.client}, () => {
                    this.getClientData(this.state.selectedClient);
                })
            }
        }
    };

    getClientData (clientName) {
        this.resetClientData();
        toraPortalApiService.getAvailableReportDates(clientName, utils.reportsTypeFolderNameMap['limits']).then((dates) => {
            if (dates.clientData.length === 0) {
                this.setState({
                    funds: [],
                    loadingData: false
                });
                return false;
            }

            let funds = [];
            dates.clientData.forEach((obj, index) => {
                if (obj.fundName) {
                    funds.push(obj.fundName)
                }
            });

            this.setState({
                clientData: dates.clientData,
                funds: funds,
            }, () => {
                this.onFundClick(funds[0]);
            });

        }).catch( e => {
            this.setState({
                funds: [],
                loadingData: false
            });
        });
    };

    onClientChange = () => {
        this.setState({loadingData: true});
        let clientName = JSON.parse(localStorage.getItem('activeClientName'));

        this.setState({
            selectedClient: clientName,
        }, () => {this.getClientData(clientName)})

    };

    getLatestUserReports() {
        let {years} = this.state;

        let latestYear = years[years.length -1 ];
        this.setState({currentYear:latestYear}, () => {
            this.getClientAvailableMonths(latestYear).then((months) => {
                let latestMonth = months[months.length - 1];
                this.onMonthClick(latestMonth);
            })
        });
    };

    getClientAvailableYears (currentFund) {
        //Get Years for selected client
        let { clientData } = this.state,
            years = [];

        clientData.forEach((fundObj, index) => {
            if (fundObj.fundName === currentFund) {
                fundObj.availableReports.forEach((report) => {
                    if (report.year){
                        years.push(report.year);
                    }
                })
            }
        });

        this.setState({years: years.sort()}, ()=> {
            this.getLatestUserReports();
        })
    };

    getClientAvailableMonths (year) {
        //Get Months for client
        let {clientData, currentFund} = this.state;
        let months = [];

        clientData.forEach((fundObj) => {
            if (fundObj.fundName === currentFund) {
                fundObj.availableReports.forEach((rep) => {
                    if (rep.year === year) {
                        months = rep.availableMonths.sort((a, b) => {
                            return a - b;
                        });
                        this.setState({currentMonth:months[months.length - 1]})
                    }
                })
            }
        });

        this.setState({months}, () => {
            this.onMonthClick(months[months.length - 1])
        });
        return Promise.resolve(months);
    };

    onFundClick = (currentFund) => {
        this.setState({currentFund}, () => {
            this.getClientAvailableYears(currentFund)
        });
    };


    onYearClick = (currentYear) => {
        this.setState({currentYear}, () => {
            this.getClientAvailableMonths(currentYear).then((months) => {})
        });
    };

    onMonthClick = (currentMonth) => {
        this.setState({currentMonth}, () => {
            this.getClientReports();
        });
    };

    getClientReports () {
        let {currentFund, currentYear, currentMonth} = this.state;
        this.setState({loadingData: true});
        toraPortalApiService.getAvailableReports(currentFund, currentYear, currentMonth, utils.reportsTypeFolderNameMap['limits']).then((data) => {
            this.setState({
                reports: data,
                loadingData: false
            });
            toraPortalApiService.getLimitDefinitionReportForFund(currentFund).then((report) => {
                this.setState({limitDefinitionReport: report});
            })
        })
    };

    downloadFile (downloadURL) {
        toraPortalApiService.downloadFile(downloadURL);
    }

    monthFormatter (month) {
        let monthsOfYear = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return monthsOfYear[month - 1];
    };

    dayFormatter (day) {
        let formattedDay;
        switch (day) {
            case 1:
                formattedDay = '1st';
                break;
            case 2:
                formattedDay = '2nd';
                break;
            case 3:
                formattedDay = '3rd';
                break;
            default:
                formattedDay = `${day}th`;
        }
        return formattedDay;
    };

    render () {
        if (!utils.canViewLimitFiles() && !utils.isUserAdmin()) {
            return <Redirect to={'/resources'}/>
        }

        let { userIsAdmin, selectedClient, years, months, reports,
            currentYear, currentMonth, funds, currentFund, limitDefinitionReport, loadingData} = this.state;

        return (
            <Loadable active={this.state.loadingData} spinner text={this.state.loadingText}>
                <div className="full-page-content small">

                    {
                        userIsAdmin &&
                        <ClientsSidebar onClientChange={this.onClientChange}/>
                    }

                    <div id={'content'} className="content" style={{'paddingTop': '35px'}}>
                        <Card className="card-stats card-raised executions-container">

                            <CardHeader>
                                <h4 className="titleContainerStyle"> {selectedClient} </h4>
                                <i className="now-ui-icons arrows-1_minimal-right"> </i>
                                <h4 className="titleContainerStyle"> Limit </h4>
                            </CardHeader>

                            <CardBody>

                                {
                                    funds.length ?
                                        <div className="container-for-dates">

                                            <div className="datesContainerStyle">
                                                {
                                                    funds.map((fund) => {
                                                        return (
                                                            <div className="fundStyle"
                                                                 onClick={e => this.onFundClick(fund)}>
                                                    <span
                                                        className={'date-text ' + (currentFund === fund ? 'active' : '')}> {fund} </span>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>

                                            {
                                                years.length ?
                                                    <div className="datesContainerStyle">
                                                        <div className="dateTitleStyle">Years:</div>
                                                        {
                                                            years.map((year) => {
                                                                return (
                                                                    <div className="dateStyle"
                                                                         onClick={e => this.onYearClick(year)}>
                                                    <span
                                                        className={'date-text ' + (currentYear === year ? 'active' : '')}> {year} </span>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    ''
                                            }

                                            {
                                                months.length ?
                                                    <div className="datesContainerStyle">
                                                        <div className="dateTitleStyle"> Months:</div>
                                                        {
                                                            months.map((month) => {
                                                                return (
                                                                    <div className="dateStyle"
                                                                         onClick={e => this.onMonthClick(month)}>
                                                            <span
                                                                className={'date-text ' + (currentMonth === month ? 'active' : '')}> {this.monthFormatter(month)} </span>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    ''
                                            }

                                            {
                                                limitDefinitionReport ?
                                                    <div className="datesContainerStyle border-hr"
                                                         style={{paddingTop: '15px'}}>
                                                        <div className='full-reports'>
                                                            <span className="full-rep">Limit Definition Report: </span>
                                                        </div>
                                                        <div className="rep-file-container">
                                                            <span className='custom-report' style={{ fontSize: '12px' }}
                                                                  onClick={(e) => this.downloadFile(limitDefinitionReport.downloadURL)}>
                                                                <i style={{fontSize: '10px', paddingRight: '8px'}}
                                                                   className="now-ui-icons arrows-1_cloud-download-93"/>
                                                                {limitDefinitionReport.name + '.' + limitDefinitionReport.extension}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    ''
                                            }

                                            {
                                                reports.length > 0 ?
                                                    <div className='table-container' style={{width: '100%'}}>
                                                        <LimitReportsTable data={reports}
                                                                           year={currentYear}
                                                                           month={currentMonth}/>

                                                    </div>
                                                    :
                                                    ''
                                            }

                                        </div>
                                        :
                                        <div>
                                            {
                                                loadingData ?
                                                    ''
                                                    :
                                                    <div>
                                                        {
                                                            userIsAdmin ?
                                                                <div className='no-reports-message'> No reports available for this client </div>
                                                                :
                                                                <div className='no-reports-message'> No reports available </div>
                                                        }
                                                    </div>
                                            }
                                        </div>
                                }

                            </CardBody>
                        </Card>
                    </div>
                    <div style={{clear: 'both'}}> </div>
                </div>
            </Loadable>
        );
    }



}

export default LimitReportsPage;
