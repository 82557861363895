import React, {useEffect, useRef, useState} from 'react';
import {Redirect} from "react-router-dom";
import {Card, CardBody, CardHeader} from "reactstrap";
import Loadable from "react-loading-overlay";
import toraPortalApiService from "../../../services/ToraPortalApiService";
import utils from "../../../utils";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CapabilitiesTable from "./CapabilitiesTable";
import NotificationAlert from "react-notification-alert";
import ClientsSidebar from "../../../components/ClientsSidebar/ClientsSidebar";
import '../../../assets/scss/tora-dashboard/_capabilities.scss';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import {getTableFiltersKey, tableNames} from "./capabilitiesUtils";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function computeTabProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

function CapabilitiesPage() {
    const loadingText = 'Loading';
    let localActiveClient = localStorage.getItem('activeClientName');
    const selectedClientForAdmin = localActiveClient ? JSON.parse(localActiveClient) : null;
    const userData = utils.getUserData();
    let client = userData ? userData.client : '';
    const [selectedClient, setSelectedClient] = useState(selectedClientForAdmin == null ? client
                                                                                        : selectedClientForAdmin);
    const [isLoading, setIsLoading] = useState(true);
    const [capabilities, setCapabilities] = useState({});
    const [tabValue, setTabValue] = useState(0);
    const notificationRef = useRef();
    const [consolidateUser, setConsolidateUser] = useState(false);
    const [consolidateExchange, setConsolidateExchange] = useState(false);
    const [funds, setFunds] = useState([]);
    const [selectedFund, setSelectedFund] = useState(null);

    useEffect(() => {
        fetchFunds(selectedClient);
    }, [selectedClient]);

    useEffect(() => {
        if (selectedFund) {
            fetchCapabilities(selectedFund, consolidateUser, consolidateExchange)
        }
    }, [selectedFund, consolidateUser, consolidateExchange])

    useEffect(() => {
        if (funds && funds.length > 0) {
            setSelectedFund(funds[0])
        }
    }, [funds])

    useEffect(() => {
        window.addEventListener("beforeunload", removeTableFilters);

        return () => {
            window.removeEventListener("beforeunload", removeTableFilters);
        };
    }, []);

    const fetchFunds = (clientName) => {
        let resultFunds = null;
        toraPortalApiService.getAllFundsForClient(clientName)
                            .then(funds => {
                             setFunds(funds)
                             resultFunds = funds
                         })
                            .catch(err => {
                             console.error(err);
                             showNotification('Error while loading the data!', 'danger');
                         })
                            .finally(() => {

                             // if the funds is successfully retrieved the loading will stop when all capabilities
                             // will be retrieved or an error occurs
                             if (!resultFunds) {
                                 setIsLoading(false);
                             }
                         })
    }

    const fetchCapabilities = (fund, consolidateUser, consolidateExchange) => {
        const fundName = fund.name;
        const accountCapabilitiesResponse = toraPortalApiService.getAllAccountCapabilities(fundName,
                                                                                           consolidateUser,
                                                                                           consolidateExchange);
        const brokerCapabilitiesResponse = toraPortalApiService.getAllBrokerCapabilities(fundName,
                                                                                         consolidateUser,
                                                                                         consolidateExchange);
        const marketCapabilitiesResponse = toraPortalApiService.getAllMarketCapabilities(fundName,
                                                                                         consolidateUser,
                                                                                         consolidateExchange);
        const iatbCapabilitiesResponse = toraPortalApiService.getAllInternalAccountAndTradebookCapabilities(fundName,
                                                                                                            consolidateUser,
                                                                                                            consolidateExchange);

        Promise.all([accountCapabilitiesResponse, brokerCapabilitiesResponse, marketCapabilitiesResponse,
                        iatbCapabilitiesResponse])
               .then((response) => {
                   setCapabilities(response);
               })
               .catch((err) => {
                   console.error(err);
                   showNotification('Error while loading the data!', 'danger');
               })
               .finally(() => {
                   setIsLoading(false);
               })
    }

    const showNotification = (message, type = 'primary') => {
        const options = {
            place: 'tc',
            message: (
                <div style={{marginTop: 400 + 'px !important;'}}>
                    <div style={{marginTop: 400 + 'px !important;'}}>
                        {message}
                    </div>
                </div>
            ),
            type: type,
            icon: "now-ui-icons ui-1_bell-53",
            autoDismiss: 7
        }
        if(notificationRef.current) {
            notificationRef.current.notificationAlert(options);
        }
    }

    const onTabChange = (event, newTabValue) => {
        removeTableFilters();
        setTabValue(newTabValue);
    }

    const onClientChange = () => {
        let clientName = JSON.parse(localStorage.getItem('activeClientName'));

        if (clientName !== selectedClient) {
            removeTableFilters();
            setIsLoading(true);
            setSelectedFund(null);
            setSelectedClient(clientName);
        }

    }

    const onConsolidate = (consolidateUser, consolidateExchange) => {
        setConsolidateUser(consolidateUser);
        setConsolidateExchange(consolidateExchange);
    }

    const removeTableFilters = () => {
        tableNames.map(tableName => {
            localStorage.removeItem(getTableFiltersKey(tableName))
        })
    }

    const renderTabTables = () => {
        return tableNames.map((tableName, index) => {
            return (
                <TabPanel value={tabValue} index={index}>
                    <CapabilitiesTable
                        data={capabilities[index]}
                        tableName={tableName}
                        selectedClient={selectedClient}
                        consolidateUser={consolidateUser}
                        consolidateExchange={consolidateExchange}
                        onConsolidate={(consolidateUser, consolidateExchange) => onConsolidate(consolidateUser,
                                                                                               consolidateExchange)}
                    />
                </TabPanel>
            )
        })
    }

    const onFundClick = (fund) => {
        if (fund !== selectedFund) {
            setIsLoading(true);
            setSelectedFund(fund)
        }
    };

    if (!utils.canViewExecutionsFiles() && !utils.isUserAdmin()) {
        return <Redirect to={'/resources'}/>
    }

    return (
        <Loadable active={isLoading} spinner text={loadingText}>
            <div className="full-page-content small">
                {
                    utils.isUserAdmin() &&
                    <ClientsSidebar onClientChange={onClientChange}/>
                }
                <div id={'content'} className="content" style={{'paddingTop': '35px'}}>
                    <NotificationAlert ref={notificationRef}/>
                    <Card className="card-stats card-raised executions-container capabilities-container">
                        <CardHeader>
                            <h4 className="titleContainerStyle"> {selectedClient} </h4>
                        </CardHeader>
                        <CardBody>
                            {
                                funds.length ?
                                <div className="container-for-dates">
                                    <div className="datesContainerStyle">
                                        {
                                            funds.map((fund) => {
                                                return (
                                                    <div className="fundStyle"
                                                         onClick={() => onFundClick(fund)}>
                                                    <span
                                                        className={'date-text ' + (selectedFund === fund ? 'active'
                                                                                                         : '')}> {fund.name} </span>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div> : ''
                            }
                            <div className="container-for-dates">
                                <div style={{flexGrow: 1, width: '100%'}}>
                                    <AppBar position="static">
                                        <Tabs
                                            value={tabValue}
                                            onChange={onTabChange}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="scrollable auto tabs example"
                                        >
                                            <Tab label="Accounts" {...computeTabProps(0)} />
                                            <Tab label="Certified Brokers" {...computeTabProps(1)} />
                                            <Tab label="Market Access" {...computeTabProps(2)} />
                                            <Tab label="Internal Account and Tradebook" {...computeTabProps(3)} />
                                        </Tabs>
                                    </AppBar>
                                    {renderTabTables()}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Loadable>
    );
}

export default CapabilitiesPage;
