import React from 'react'
import FetchEnvironmentContent from './FetchEnvironmentContent'
import SequentialScriptLoader from "./SequentialScriptLoader";

class EnvironmentContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      environment: props.match.params.environment,
      someScriptFailedLoading: false
    }

    this.scriptFailedLoading = this.scriptFailedLoading.bind(this)
  }

  scriptFailedLoading (error) {
    console.log(`Caught an error while loading a script: ${error}`)
    this.setState({ someScriptFailedLoading: true })
  }

  static loadTimeline () {
    const loadTimelineScript = document.createElement('script')
    const loadTimelineScriptBody = document.createTextNode("google.charts.load('current', {packages:['timeline']});")

    loadTimelineScript.appendChild(loadTimelineScriptBody)
    document.head.appendChild(loadTimelineScript)
  }

  render () {
    const urls = [
      'https://ajax.googleapis.com/ajax/libs/jquery/2.1.0/jquery.min.js',
      'https://ajax.googleapis.com/ajax/libs/jqueryui/1.10.4/jquery-ui.min.js',
      `/dashboard/environments/${this.state.environment}/js/ui.js`,
      `/dashboard/environments/${this.state.environment}/js/utils.js`,
      `/dashboard/environments/${this.state.environment}/js/moment.min.js`,
      `/dashboard/environments/${this.state.environment}/js/lz-string-1.3.3-min.js`,
      `/dashboard/environments/${this.state.environment}/js/bootstrap-datepicker.min.js`,
      'https://www.gstatic.com/charts/loader.js',
      `/dashboard/environments/${this.state.environment}/js/data.js`,
      `/dashboard/environments/${this.state.environment}/js/timeline.js`,
      `/dashboard/environments/${this.state.environment}/js/app.js`
    ];

    if (this.state.someScriptFailedLoading) {
      return (
          <p style={{ fontSize: '20px' }}>There is currently no data for the selected environment.</p>
      )
    } else {
      return (
          <React.Fragment>

            <SequentialScriptLoader urls={urls} onError={this.scriptFailedLoading} endFunction={EnvironmentContainer.loadTimeline}/>

            <FetchEnvironmentContent content={this.state.environment}/>

          </React.Fragment>
      )
    }
  }
}

export default EnvironmentContainer
