import React from 'react'
import { Link } from 'react-router-dom'
import {
  Collapse, Navbar, NavbarToggler, Nav, NavItem, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap'

import pagesRoutes from '../../routes/pages.jsx'
import images from '../../assets/img'

import utils from '../../utils'
import toraConfig from "../../config";

class PagesHeader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.toggle = this.toggle.bind(this)
    this.activeRoute.bind(this)
    this.dropdownToggle = this.dropdownToggle.bind(this)
  }

  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute (routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }

  dropdownToggle (e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    })
  }

  renderDashboardLink () {
    if (utils.userIsAuthenticated() && utils.isOnboardingCompleted()) {
      return (
        <NavItem>
          <Link to='/dashboard' className="nav-link"><i className="now-ui-icons design_bullet-list-67"></i> Dashboard</Link>
        </NavItem>
      )
    }
  }

  renderProfileMenu () {
    if (utils.userIsAuthenticated()) {
      return (
        <Dropdown nav isOpen={this.state.dropdownOpen} toggle={(e) => this.dropdownToggle(e)}>
          <DropdownToggle caret nav>
            <i className="now-ui-icons users_single-02"></i>
            <p>
              <span className="d-lg-none d-md-block">User Menu</span>
            </p>
          </DropdownToggle>
          <DropdownMenu right>
            {utils.isKYCCompleted() && <DropdownItem tag="a" href="/dashboard/settings">Settings</DropdownItem>}
            {utils.isUserAdmin() && <DropdownItem tag="a" href={toraConfig.TORA_ADMIN_PORTAL_LINK}>LSEG TORA Portal Admin Panel</DropdownItem>}
            <DropdownItem tag="a" onClick={utils.logoutUser}>Log Out</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )
    }
  }

  renderLoginLink () {
    if (!utils.userIsAuthenticated()) {
      return (
        <NavItem>
          <Link to='/login' className="nav-link"><i className="now-ui-icons design_bullet-list-67 users_circle-08"></i> Login</Link>
        </NavItem>
      )
    }
  }

  render () {
    return (
      <Navbar expand="lg" className="bg-white navbar-absolute">
        <Container fluid={true}>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <NavbarToggler onClick={this.toggle}>
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </NavbarToggler>
            </div>
            <Link to="/" className="navbar-brand">
              <div className="navbar-logo-container">
                <img className="logo-img" src={images.lsegToraLogo} alt="lseg tora logo" />
                <span className="logo-text">Portal</span>
              </div>
            </Link>
          </div>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {this.renderDashboardLink()}
              {this.renderLoginLink()}
              {this.renderProfileMenu()}
              {
                pagesRoutes.map((prop, key) => {
                  if (prop.invisible) return null
                  if (prop.redirect) { return null }
                  return (
                    <NavItem key={key} className={this.activeRoute(prop.path)}>
                      <Link to={prop.path} className="nav-link"><i className={'now-ui-icons ' + prop.icon}></i> {prop.short}</Link>
                    </NavItem>
                  )
                })
              }
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default PagesHeader
