import React from 'react';
import Loadable from "react-loading-overlay";
import {Card, CardBody, CardHeader} from "reactstrap";
import utils, {applyFilters, formatDateWithSeconds, mapColumns} from "../../utils";
import {Redirect} from "react-router-dom";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import * as XLSX from 'xlsx';
import toraPortalApiService from "../../services/ToraPortalApiService";
import '../../assets/scss/tora-dashboard/_client-versions.scss';
import {sortAs} from "react-pivottable/Utilities";

const clientVersionsSyncName = 'client_versions_last_sync'

const columnMapping = {
  disabled: 'Client Status',
  clientName: 'Client Name',
  env: 'Environment',
  version: 'Version',
};

const valueMapping = {
  disabled: {
    true: 'Disabled',
    false: 'Enabled'
  }
}

const customSorters = {
  'Client Status': sortAs(["Enabled", "Disabled"]),
}

class ClientVersionsReportsPage extends React.Component {

  constructor(props) {
    super(props);

    const rows = Object.values(columnMapping);

    this.state = {
      lastSync: null,
      user: utils.getUserData(),
      loadingData: true,
      loadingText: 'Loading client versions...',
      pivotState: {
        data: [],
        rows: rows,
        cols: [],
        sorters: customSorters,
        rendererName: 'Table',
      },
      filteredData: [],
      valueFilter: {}
    };
  };

  componentDidMount() {
    this.fetchLatestData();
  }

  fetchLatestData = () => {
    this.fetchClientVersions();
    this.fetchLastClientVersionsSync();
  }

  fetchClientVersions = () => {
    toraPortalApiService.getAllClientVersions().then(data => {
      const mappedData = mapColumns(data, columnMapping, valueMapping);
      this.setState({
                      loadingData: false,
                      pivotState: {
                        ...this.state.pivotState,
                        data: mappedData
                      },
                      filteredData: mappedData
                    });
    }).catch(error => {
      console.error('Failed to fetch client versions: ', error);
    });
  }

  fetchLastClientVersionsSync = () => {
    toraPortalApiService.getLastSync(clientVersionsSyncName).then(data => {
      this.setState({lastSync: formatDateWithSeconds(data.updateTime)});
    }).catch(error => {
      console.error('Failed to fetch client versions: ', error);
    });
  }

  syncClientVersions = () => {
    this.setState({loadingData: true});
    toraPortalApiService.syncClientVersions().then(() => {
      this.fetchLatestData();
    }).catch(error => {
      console.error('Failed to synced client versions: ', error);
    });
  }

  applyUserViewFiltering = (filteredData) => {
    const {rows, cols} = this.state.pivotState

    const sortedData = filteredData.sort((a, b) => {
      for (let row of rows) {
        // in case of equality, we want to compare the next column, so we don't return 0 inside for loop
        if(row === "Client Status") {
          if(a[row] === "Enabled" && b[row] === "Disabled") return -1;
          if(b[row] === "Enabled" && a[row] === "Disabled") return 1;
        } else {
          if (a[row] < b[row]) return -1;
          if (a[row] > b[row]) return 1;
        }
      }
      return 0;
    });

    return sortedData.map(item => {
      let row = {};
      rows.forEach(r => row[r] = item[r]);
      cols.forEach(c => row[c] = item[c]);
      return row;
    })
  }

  exportToExcel = () => {
    const filteredData = this.state.filteredData;

    const preparedData = this.applyUserViewFiltering(filteredData)

    const ws = XLSX.utils.json_to_sheet(preparedData);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Client versions");
    XLSX.writeFile(wb, "ClientVersionsReports.xlsx");
  }

  handlePivotTableChange = (newState) => {
    const oldRowConfig = this.state.pivotState.rows
    if (oldRowConfig.length !== newState.rows.length){
      newState.rows = oldRowConfig
    }
    newState.cols = [];
    this.setState({
                    filteredData: applyFilters(newState.data, newState.valueFilter),
                    pivotState: newState
                  })
  }

  render() {
    if (!utils.isUserAdmin()) {
      return <Redirect to={'/resources'}/>
    }
    let {pivotState} = this.state;

    return (
        <Loadable active={this.state.loadingData} spinner text={this.state.loadingText}>
          <div className="full-page-content small">
            <div id={'content'} className="content" style={{'paddingTop': '35px'}}>
              <Card className="card-stats card-raised tca-container">

                <CardHeader>
                  <h4 className="titleContainerStyle">Client Versions</h4>
                </CardHeader>

                <div className="card-body">
                  <CardBody>
                    <div className="container-buttons">
                      <div>Last sync: {this.state.lastSync}</div>
                      <button onClick={this.syncClientVersions}>Update Client Versions</button>
                      <button onClick={this.exportToExcel}>Export to Excel</button>
                    </div>
                    <div className="pivotal-table-hide pivotal-table-font">
                      <PivotTableUI
                          onChange={this.handlePivotTableChange}
                          {...pivotState}
                          sorters={customSorters}
                      />
                    </div>
                  </CardBody>
                </div>
              </Card>
            </div>
          </div>
        </Loadable>
    );
  }

}

export default ClientVersionsReportsPage;
