import React from 'react';
import PropTypes from 'prop-types';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import {
    CardHeader, CardTitle, CardBody, CardFooter,
    Row, Col,
    Form, FormGroup, InputGroup, InputGroupAddon, Input, Label, Alert
} from 'reactstrap';


import {Button, SubmitButton} from '../../components';

import toraPortalApiService from '../../services/ToraPortalApiService';
import toraConfig from '../../config'
import images from '../../assets/img'


class LoginForm extends React.Component{
  constructor(props){
      super(props);
      this.state = {
        email: '',
        password: '',
        rememberMe: false,
        recaptchaValid: false,
        error: '',
        loading: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = event => {
      // eslint-disable-next-line
      this.state[event.target.name] = event.target.value;
      this.setState(this.state);
  }

  handleChangeRememberMe = event => {
      this.setState({rememberMe: event.target.checked});
  }

  handleRecaptchaOnChange = value => {

      toraPortalApiService.recaptchaCheck(value).then(isValid =>{
          this.setState({recaptchaValid: isValid})
      })
                          .catch(() =>{
          this.setState({recaptchaValid: false})
      });

  }

  handleSubmit(event) {
      event.preventDefault();
      if(!this.state.recaptchaValid) {
          this.setState({error: 'Invalid credentials. Please double check your email and password.'})
          return;
      }

      if(!this.state.email || !this.state.password) {
          this.setState({error: 'Please input your email and password!'})
          return;
      }

      this.setState({ loading: true })
      toraPortalApiService.checkUser(this.state.email, this.state.password, this.state.rememberMe)
                          .then(() => {
          this.props.onUserAuthenticatedListenerFn(this.state);
        })
                          .catch((err) => {
          this.setState({error: err.errorMessage})
        })
                          .finally(() => {
            this.setState({ loading: false })
        })
  }

    componentDidMount() {
        console.log("Test:" + toraConfig.GOOGLE_RECAPTCHA_SITE_KEY)
        loadReCaptcha(toraConfig.GOOGLE_RECAPTCHA_SITE_KEY)
    }

    componentWillUnmount() {
        document.getElementById('login_form').reset();
    }

    renderFormError() {
        return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
    }

  render() {
    return(
        <div className={toraConfig.SHOW_SSO_LOGIN ? 'container' : ''}>
            { toraConfig.SHOW_SSO_LOGIN &&
                <Button className='sso' href='/login-sso'>Continue with LSEG TORA</Button>
            }
            <CardHeader className="text-center">
                <CardTitle>Sign in to your account</CardTitle>
            </CardHeader>
            <CardBody>
                <Form id="login_form" onSubmit={this.handleSubmit}>
                    {this.renderFormError()}
                    <InputGroup className={(this.state.emailFocus ? "input-group-focus":"")}>
                        <InputGroupAddon ><i className="now-ui-icons ui-1_email-85"></i></InputGroupAddon>
                        <Input type="email" placeholder="Your Email..." name="email" onChange={this.handleChange} onFocus={(e) => this.setState({emailFocus: true})} onBlur={(e) => this.setState({emailFocus: false})}/>
                    </InputGroup>
                    <InputGroup className={(this.state.lastnameFocus ? "input-group-focus":"")}>
                        <InputGroupAddon ><i className="now-ui-icons ui-1_lock-circle-open"></i></InputGroupAddon>
                        <Input type="password" placeholder="Password..." name="password" onChange={this.handleChange} onFocus={(e) => this.setState({lastnameFocus: true})} onBlur={(e) => this.setState({lastnameFocus: false})} />
                    </InputGroup>
                    <ReCaptcha
                        sitekey={toraConfig.GOOGLE_RECAPTCHA_SITE_KEY}
                        action="login"
                        verifyCallback={this.handleRecaptchaOnChange}
                    />
                    <div className="sign-in-control-container">
                        <Row>
                            <Col md={6} xs={12}>
                                <FormGroup check className="form-check">
                                    <Label check>
                                        <Input type="checkbox" name="rememberMe" onChange={this.handleChangeRememberMe}  {...this.props.inputProps} />
                                        <span className="form-check-sign"></span>
                                        <div>Remember me</div>
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col md={6} xs={12}>
                                <SubmitButton data-action="login" className="btn btn-primary sign-in-button" type="submit" loading={this.state.loading}>
                                    Sign In
                                </SubmitButton>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </CardBody>
            <CardFooter className="text-center">
                <div>Forgot password? <a href="forgot-password" className="external-link">Click here to reset it.</a></div>
            </CardFooter>
        </div>
    )
  }
}

LoginForm.propTypes = {
  onUserAuthenticatedListenerFn: PropTypes.func,
}

export default LoginForm;
