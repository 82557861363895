import React from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

import { Button } from '../../components'

import defaultImage from '../../assets/img/image_placeholder.jpg'
import defaultAvatar from '../../assets/img/placeholder.jpg'

class FileUpload extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      disabled: props.disabled,
      disabledClass: props.disabled ? ' cursor-not-allowed' : ''
    }
    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  handleImageChange (e) {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result
      })
    }
    if (this.props.onChange) {
      this.props.onChange(e)
    }
    reader.readAsDataURL(file)
  }

  handleSubmit (e) {
    e.preventDefault()
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }

  handleClick () {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = this.handleImageChange
    input.click()
  }

  handleRemove () {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage
    })
    if (this.props.onRemove) {
      this.props.onRemove()
    }
  }

  createFileNameExcerpt (file) {
    const name = file.name
    const base = name.substring(0, name.lastIndexOf('.'))
    const extension = name.substring(name.lastIndexOf('.'))
    return base.substr(0, 10) + '..' + extension
  }

  render () {
    return (
      <div className={'row ' + this.props.className}>
        <div className="col-6 vertical-middle">
          {this.props.isUploading
            ? (<i className="now-ui-icons loader_gear spin"></i>)
            : null
          }
          {(this.state.file ? this.createFileNameExcerpt(this.state.file) : 'No file chosen')}
        </div>
        {this.state.file === null
          ? (
            <div className="col-6">
              <span className={'float-right ' + this.state.disabledClass}>
                <Button outline size="sm" disabled={this.state.disabled}
                  onClick={() => this.handleClick()}>{this.props.avatar ? 'Add Photo' : 'Select file'}</Button>
              </span>
            </div>
          )
          : (
            <div className="col-6 vertical-middle no-padding">
              <span className={'float-right ' + this.state.disabledClass}>
                <Button size="sm" onClick={() => this.handleClick()} disabled={this.state.disabled}>Change</Button>
                {this.props.avatar ? (<br/>) : null}
                {!this.props.isUploading &&
                <Button size="sm" className={this.props.removeClass} color="danger"
                  onClick={() => this.handleRemove()}>
                  <i className="now-ui-icons ui-1_simple-remove"/>
                </Button>
                }
              </span>
            </div>
          )}
      </div>
    )
  }
}

FileUpload.propTypes = {
  avatar: PropTypes.bool,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  removeClass: PropTypes.string,
  isUploading: PropTypes.bool,
  className: PropTypes.string
}

export default FileUpload
