import React from 'react';
import toraPortalApiService from "../../services/ToraPortalApiService";


class ClientsSidebar extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            activeClient: '',
            clientsList: [],
            inputValue: '',
            transformedClientsList: [],
            sidebarIsOpen : undefined,
        };

        this.handleChange = this.handleChange.bind(this);
    };

    handleChange(event) {
        let filter = event.target.value;
        localStorage.setItem('inputFilter', filter);
        this.inputTyping(filter);
    };


    inputTyping(filter){
        let { clientsList } = this.state,
            newClientsList = [];

        this.setState({
            inputValue: filter,
            transformedClientsList: clientsList
        });

        if (filter && !filter.length) return null;

        clientsList.forEach((client) => {
            let upperCaseClientName = client.name.toUpperCase();
            if (upperCaseClientName.includes(filter.toUpperCase())) {
                newClientsList.push(client)
            }

        });

        localStorage.setItem('storedClientsList', JSON.stringify(newClientsList));
        this.setState({transformedClientsList: newClientsList})
    }


    componentWillMount() {
        let sidebarStatus = localStorage.getItem('sidebarStatus') || true,
            client = localStorage.getItem('activeClientName'),
            storedClientsList = localStorage.getItem('storedClientsList'),
            inputFilter = localStorage.getItem('inputFilter');

        this.setState({sidebarIsOpen: JSON.parse(sidebarStatus)});

        if (!client) {
            toraPortalApiService.getUserDetails().then((user) => {
                this.setState({activeClient: user.client});
                localStorage.setItem('activeClientName', JSON.stringify(user.client));
            })
        } else {
            this.setState({activeClient: JSON.parse(client)});
        }

        if (storedClientsList) {
            let preparedList = JSON.parse(storedClientsList);
            this.setState({clientsList: preparedList, transformedClientsList: preparedList}, () => {
                if(inputFilter && inputFilter.length > 0){
                    this.inputTyping(inputFilter)
                }
            });
        }
    };


    componentDidMount() {
        let { sidebarIsOpen } = this.state;
        let inputFilter = localStorage.getItem('inputFilter');

        let rightSideContainer = document.getElementById('content'),
            footer = document.getElementById('footer');

        if (sidebarIsOpen === true) {
            rightSideContainer.style.width = '85%';
            footer.style.paddingLeft= '15%';
        } else {
            rightSideContainer.style.width = '99%';
            footer.style.paddingLeft= '0 1%';
        }

        toraPortalApiService.getClientList()
                            .then((clientsList) => {
                this.setState({clientsList});
                localStorage.setItem('storedClientsList', JSON.stringify(clientsList));
                this.inputTyping(inputFilter || '');
            })
    };

    selectClient = (activeClient) =>  {
        this.setState({activeClient}, () => {
            localStorage.setItem('activeClientName', JSON.stringify(activeClient));
            this.props.onClientChange();
        })
    };

    switchSidebar = () => {
        this.setState({sidebarIsOpen: !this.state.sidebarIsOpen}, () => {
            localStorage.setItem('sidebarStatus', this.state.sidebarIsOpen);
        });
        let rightSideContainer = document.getElementById('content'),
            footer = document.getElementById('footer');

        if (this.state.sidebarIsOpen === true ) {
            rightSideContainer.style.width = '99%';
            footer.style.padding= '0 1%';
        } else {
            rightSideContainer.style.width = '85%';
            footer.style.paddingLeft= '15%';
        }
    };


    render(){

        let { activeClient, transformedClientsList, inputValue, sidebarIsOpen } = this.state;

        return(
            <div className={sidebarIsOpen ? 'client-sidebar-container' : 'client-sidebar-container hide' }>

                <div className='sidebar-full-container'>

                    <div className={sidebarIsOpen ? 'sidebar-switch' : 'sidebar-switch overflow-left'} onClick={() => this.switchSidebar()}>
                        <i className={sidebarIsOpen ? 'now-ui-icons arrows-1_minimal-left icon-left' : 'now-ui-icons arrows-1_minimal-left icon-right' }> </i>
                    </div>

                    <h4 style={{'marginBottom': '0', 'color': '#464646', 'fontSize':'1.514em'}}>View Data For: </h4>
                    {
                        activeClient.length ?
                            <div>
                                <h4 style={{'marginTop': '0', 'marginBottom': '0', 'fontSize':'1.514em'}}>
                                    <i className="now-ui-icons arrows-1_minimal-right" style={{fontSize: '10px', 'paddingRight': '10px'}}> </i>
                                    {activeClient}
                                </h4>
                            </div>
                            :
                            ""
                    }

                    <div className='search-container'>
                        <input type="text" placeholder='Search' value={inputValue} onChange={this.handleChange} />
                    </div>


                    {
                        transformedClientsList.length ?
                            <div className='clients-list-container'>
                                {
                                    transformedClientsList.map((client) => {
                                        return(
                                            <div key={client.id}  className={activeClient === client.name ? 'client-name active': 'client-name'}
                                                 onClick={() => this.selectClient(client.name)}>
                                                <span className='bullet'>&nbsp;</span>
                                                {client.name}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            :
                            <div className='no-clients-found-message'>
                                No results
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default ClientsSidebar;
