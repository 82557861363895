import React from 'react';
import {
    Card, CardHeader, CardTitle, CardBody, CardFooter,
    Container, Row, Col,
    Form, InputGroup, InputGroupAddon, Input, Alert
} from 'reactstrap';

import { SubmitButton } from '../../components';

import toraPortalApiService from '../../services/ToraPortalApiService';
import validationUtils from '../../utils-validations';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import toraConfig from '../../config';

class ForgotPassword extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            recaptchaValid: false,
            error: '',
            success: '',
            loading: false
          };

          this.handleRequestPasswordChange = this.handleRequestPasswordChange.bind(this);
    }

    handleRequestPasswordChange(event) {
        event.preventDefault();

        if(!this.state.recaptchaValid) {
            this.setState({error: 'Invalid credentials. Please double check your email and password.'});
            return;
        }

        if(!validationUtils.isValidEmail(this.state.email)) {
            this.setState({error: 'Please input a valid email!'});
            return;
        }

        this.setState({
          error: null,
          success: null,
          loading: true
        })

        toraPortalApiService.resetPasswordSendEmail(this.state.email)
                            .then(() => {
                this.setState({success: 'You will soon receive an email with a link. Access it and follow the instructions to change your password!'});
                this.setState({
                    email: '',
                    loading: false
                })
            })
                            .catch((err) => {
                this.setState({
                    error: err.errorMessage,
                    loading: false
                })
            });
    }

    renderFormError() {
        return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
    }

    renderFormSuccess() {
        return ((this.state.success) ? <Alert color="success">{this.state.success}</Alert> : '')
    }

    handleChange = event => {
        // eslint-disable-next-line
        this.state[event.target.name] = event.target.value;
        this.setState(this.state);
    }

    handleRecaptchaOnChange = value => {

        toraPortalApiService.recaptchaCheck(value).then(isValid =>{
            this.setState({recaptchaValid: isValid})
        })
                            .catch(() =>{
            this.setState({recaptchaValid: false})
        });
    }

    componentDidMount() {
        loadReCaptcha(toraConfig.GOOGLE_RECAPTCHA_SITE_KEY)
    }

    render(){
        return (
            <div className="forgot-password">
                <div className="full-page-content">
                    <div className="justify-content-center">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg={6} md={8} xs={12}>
                                    <Card className="card-form">
                                        <CardHeader className="text-center">
                                            <CardTitle>
                                                <div>Reset your password</div>
                                            </CardTitle>
                                        </CardHeader>
                                        <div className="text-center">You will receive a link to create a new password via email.</div>
                                        <CardBody className="col-md-8 margin-auto">
                                            <Form onSubmit={this.handleRequestPasswordChange}>
                                                {this.renderFormError()}
                                                {this.renderFormSuccess()}
                                                <InputGroup className={(this.state.emailFocus ? "input-group-focus":"")}>
                                                    <InputGroupAddon ><i className="now-ui-icons ui-1_email-85"></i></InputGroupAddon>
                                                    <Input type="email" placeholder="Your Email..." name="email" value={this.state.email} onChange={this.handleChange} onFocus={(e) => this.setState({emailFocus: true})} onBlur={(e) => this.setState({emailFocus: false})}/>
                                                </InputGroup>
                                                <ReCaptcha
                                                    sitekey={toraConfig.GOOGLE_RECAPTCHA_SITE_KEY}
                                                    action="resetpw"
                                                    verifyCallback={this.handleRecaptchaOnChange}
                                                />
                                                <SubmitButton data-action="resetpw"  color="#1e9fa8" className="btn btn-primary button-new-password w-100" type="submit" loading={this.state.loading}>
                                                        Get new password
                                                </SubmitButton>
                                            </Form>
                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <div>Back to <a href="/" className="external-link">Sign in!</a></div>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
