import React from 'react'
import Iframe from 'react-iframe'

class DocumentationContainer extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      documentationName: props.match.params.documentationName,
      documentations: {
        oems: 'https://d3mho8beudp1zh.cloudfront.net/',
      }
    }
  }

  render () {
    return (

        <React.Fragment >
          <p style={{height: '53px', margin: 0}}/>
          <Iframe url={this.state.documentations[this.state.documentationName]}
                  width="100%"
                  height="1000px"
                  display="block"
                  position="relative"
                  allowFullScreen/>
      </React.Fragment>
    )
  }
}

export default DocumentationContainer
