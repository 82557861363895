import {useEffect, useState} from "react";

const SequentialScriptLoader = ({urls, onLoad, onError, endFunction}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < urls.length) {
      const script = document.createElement('script');
      script.src = urls[currentIndex];
      script.onload = () => {
        setCurrentIndex(currentIndex + 1);
        if (onLoad) {
          onLoad(urls[currentIndex]);
        }
      };
      script.onerror = () => {
        if (onError) {
          onError(new Error(`Failed to load script ${urls[currentIndex]}`));
        }
      };
      document.body.appendChild(script);
    } else if (currentIndex === urls.length) {
      endFunction();
    }
  }, [currentIndex, urls, onLoad, onError, endFunction]);

  return null;
};

export default SequentialScriptLoader;