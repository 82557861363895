import React from 'react';
import PropTypes from 'prop-types';
import {
    Card, CardHeader, CardTitle, CardBody, CardFooter,
    Form, InputGroup, InputGroupAddon, Input, Alert
} from 'reactstrap';

import { Button } from '../../components';

import toraPortalApiService from '../../services/ToraPortalApiService';
import validationUtils from '../../utils-validations';

class ResetPasswordForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            newPassword:'',
            confirmNewPassword:'',
            error:'',
            passwordsMatch:'',
            resetKey: this.props.resetKey
        };
        this.handleRequestPasswordReset = this.handleRequestPasswordReset.bind(this);
    }

    renderFormError() {
        return ((this.state.error) ? <Alert color="danger">{this.state.error}</Alert> : '')
    }

    renderFormSuccess() {
        return ((this.state.success) ? <Alert color="success">{this.state.success} Please click <a href="login">here</a> to login with your new password.</Alert> : '')
    }

    handleChange = event => {
        // eslint-disable-next-line
        this.state[event.target.name] = event.target.value;
        this.setState(this.state);
    }

    handleRequestPasswordReset(event){
        event.preventDefault();

        if(this.state.newPassword !== this.state.confirmNewPassword){
            this.setState({error: 'The two passwords fields must match!'})
            return;
        }

        if(!validationUtils.isValidPassword(this.state.newPassword) || !validationUtils.isValidPassword(this.state.confirmNewPassword)){
          this.setState({error: 'The password is not strong enough!'})
          return;
        }

        this.setState({
          error: null,
          success: null,
        })

        toraPortalApiService.initResetPassword(this.state.newPassword, this.state.resetKey)
                            .then((resp) => {
            this.setState({
                qrCodeImage: resp.image,
                secretKey: resp.secretKey
            });
            this.props.onPasswordResetListenerFn(this.state);
          })
                            .catch((error) => {
            this.setState({error: error.errorMessage})
          })
    }

    render(){
        return (
            <Card className="card-form">
                <CardHeader className="text-center">
                    <CardTitle>
                        <div>Reset your password</div>
                    </CardTitle>
                </CardHeader>
                <div className="text-center">Enter your new password below.</div>
                <CardBody className="text-center">
                    <Form onSubmit={this.handleRequestPasswordReset}>
                    {this.renderFormError()}
                    {this.renderFormSuccess()}
                        <InputGroup className={(this.state.newPasswordFocus ? "input-group-focus":"")}>
                            <InputGroupAddon ><i className="now-ui-icons ui-1_lock-circle-open"></i></InputGroupAddon>
                            <Input type="password" placeholder="New Password..." name="newPassword" onChange={this.handleChange} onFocus={(e) => this.setState({newPasswordFocus: true})} onBlur={(e) => this.setState({newPasswordFocus: false})} />
                        </InputGroup>
                        <InputGroup className={(this.state.confirmNewPasswordFocus ? "input-group-focus":"")}>
                            <InputGroupAddon ><i className="now-ui-icons ui-1_lock-circle-open"></i></InputGroupAddon>
                            <Input type="password" placeholder="Confirm new password..." name="confirmNewPassword" onChange={this.handleChange} onFocus={(e) => this.setState({confirmNewPasswordFocus: true})} onBlur={(e) => this.setState({confirmNewPasswordFocus: false})} />
                        </InputGroup>
                        <div className="instructions-container text-left">
                            Hint: The password should be at least eight characters long. To make
                            it stronger, use upper and lower case letters, numbers and symbols like ! " ? $ % ^ ).
                        </div>
                        <Button className="btn btn-primary button-new-password" type="submit">
                                Next step
                        </Button>
                    </Form>
                </CardBody>
                <CardFooter className="text-center">
                    <div>Back to <a href="/" className="external-link">Sign in!</a></div>
                </CardFooter>
            </Card>
        );
    }
}

ResetPasswordForm.propTypes = {
  resetKey: PropTypes.string.isRequired,
  onPasswordResetListenerFn: PropTypes.func,
}

export default ResetPasswordForm;
