// import pages to be routed
import LoginPage from '../views/Login/LoginPage'
import SSOLoginPage from '../views/Login/SSOLoginPage'
import ForgotPassword from '../views/ForgotPassword/ForgotPassword'
import ResetPassword from '../views/ResetPassword/ResetPassword'
import AuditReportsPage from '../views/Reports/AuditReportsPage'
import ExecutionsReportsPage from '../views/Reports/ExecutionsReportsPage'
import LimitReportsPage from '../views/Reports/LimitReportsPage'
import BorrowReportsPage from '../views/Reports/BorrowReportsPage'
import TcaReportsPage from '../views/Reports/TcaReportsPage'
import LegalLedgerReportsPage from '../views/Reports/LegalLedgerReportsPage'
import UserGuide from '../views/UserGuide/UserGuide'
import CapabilitiesPage from "../views/Reports/capabilities/CapabilitiesPage";
import utils from "../utils";
import ClientVersionsReportsPage from "../views/Reports/ClientVersionsReportsPage";

const pagesRoutes = [
  { path: '/login', invisible: true, name: 'Login Page', short: 'Login', mini: 'LP', icon: 'users_circle-08', component: LoginPage },
  { path: '/login-sso', invisible: true, name: 'Login Page', short: 'Login', mini: 'LP', icon: 'users_circle-08', component: SSOLoginPage },
  { path: '/login-alt', invisible: true, name: 'Alternative Login Page', short: 'Alt Login', mini: 'LP', icon: 'users_circle-08', component: SSOLoginPage },
  { path: '/forgot-password', invisible: true, component: ForgotPassword },
  { path: '/reset/finish', invisible: true, component: ResetPassword },
  { path: '/reports/audit', name: 'Reports -> Audit', invisible: true, component: AuditReportsPage },
  { path: '/reports/executions', name: 'Reports -> Executions', invisible: true, component: ExecutionsReportsPage },
  { path: '/reports/limit', name: 'Reports -> Limit', invisible: true, component: LimitReportsPage },
  { path: '/reports/borrow', name: 'Reports -> Borrow', invisible: true, component: BorrowReportsPage },
  { path: '/reports/tca', name: 'Reports -> TCA', invisible: true, component: TcaReportsPage },
  { path: '/reports/client-versions', name: 'Reports -> ClientVersions', invisible: true, component: ClientVersionsReportsPage },
  { path: '/reports/legal-ledger', name: 'Reports -> Legal Ledger', invisible: true, component: LegalLedgerReportsPage },
  { path: '/reports/client-setup', name: 'Reports -> Client Setup', invisible: true, component: CapabilitiesPage },
  { path: '/user-guide', name: 'User Guide', invisible: true, component: UserGuide },
  { redirect: true, path: '/', pathTo: '/login', name: 'Login' }
]

export const mapPagesRoutesPathToName = utils.createRoutesNameMap(pagesRoutes)

export default pagesRoutes
