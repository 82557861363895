import React from 'react';
import Loader from "../../components/Loader/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import toraPortalApiService from "../../services/ToraPortalApiService"


class LimitReportsTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userData: this.props.data,
            loading: false,
            tableData: [],
            dates: new Set(),
            limitReportsMap:  new Map(),
            limitChangesReportsMap: new Map(),
            limitDefinitionChangesReportsMap: new Map()
        };

        this.titleStyle = {
            'padding-right' : '8px'
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        let tableData = [];
        let dates = new Set();
        let limitReportsMap =  new Map();
        let limitChangesReportsMap =  new Map();
        let limitDefinitionChangesReportsMap = new Map();
        let sortedData = nextProps.data.sort((a, b) => (parseInt(a.date.split('-')[0], 10) > parseInt(b.date.split('-')[0], 10)) ? 1 : -1);

        sortedData.forEach((file) => {
           dates.add(file.date);

           if (file.name.startsWith('LimitReport')) {
               limitReportsMap.set(file.date, file);
           } else if (file.name.startsWith('LimitChangesReport')) {
               limitChangesReportsMap.set(file.date, file);
           } else if (file.name.startsWith('LimitDefinitionChangesReport')) {
               limitDefinitionChangesReportsMap.set(file.date, file);
           }
        });

        dates.forEach((date) => {
            tableData.push({
                date: date,
                limitReports: limitReportsMap.get(date) !== undefined ? limitReportsMap.get(date) : '',
                limitChangesReports: limitChangesReportsMap.get(date) !== undefined ? limitChangesReportsMap.get(date) : '',
                limitDefinitionChangesReports: limitDefinitionChangesReportsMap.get(date) !== undefined ? limitDefinitionChangesReportsMap.get(date) : ''
            })
        });

        if (nextProps.data !== this.props.data) {
            this.setState({
                saveButtonDisable: 'true',
                userData: tableData,
                tableData: tableData,
                dates: dates,
                limitReportsMap: limitReportsMap,
                limitChangesReportsMap: limitChangesReportsMap,
                limitDefinitionChangesReportsMap: limitDefinitionChangesReportsMap
            });
        }
    };

    textFormatter = (cell) => {
        if (!cell) return null;
        return (
            <span className="custom-report"  onClick={(e) => toraPortalApiService.downloadFile(cell.downloadURL)}>
                <i style={{fontSize: '10px', paddingRight: '5px'}} className="now-ui-icons arrows-1_cloud-download-93"> </i>
                { cell.name }
            </span>
        )
    };

    preparedColumnsConfig = () => {
        let { year, month } = this.props;

        return [
            {
                dataField: 'date',
                text: year + '.' + month,
                editable: false,
                headerClasses: 'w-10 user-table-header small-column',
                classes: 'cursor-default'
            },

            {
                dataField: 'limitReports',
                text: 'Limit Reports',
                formatter: this.textFormatter,
                editable: false,
                headerClasses: 'w-10 user-table-header medium-column-1',
                classes: 'table-cell-highlight'
            },

            {
                dataField: 'limitChangesReports',
                text: 'Limit Changes Reports',
                formatter: this.textFormatter,
                editable: false,
                headerClasses: 'w-10 user-table-header medium-column-2',
                classes: 'table-cell-highlight'
            },

            {
                dataField: 'limitDefinitionChangesReports',
                text: 'Limit Definitions Changes Reports',
                formatter: this.textFormatter,
                editable: false,
                headerClasses: 'w-10 user-table-header big-column',
                classes: 'table-cell-highlight',
            },
        ];
    };

    render() {
        let { userData } = this.state;

        return (
            <div className="dashboard-table-wrapper">
                <Loader
                    loading={this.state.loading} />

                <BootstrapTable
                    hover
                    condensed
                    keyField='id'
                    classes='table-responsive table-executions-info'
                    rowStyle={{ fontSize: '10px' }}
                    bordered={ false }
                    data={ userData }
                    columns={ this.preparedColumnsConfig() }
                />
            </div>
        );
    }
}

export default LimitReportsTable;
