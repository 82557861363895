import React from 'react';
import Loader from "../../components/Loader/Loader";
import BootstrapTable from "react-bootstrap-table-next";
import toraPortalApiService from "../../services/ToraPortalApiService";

const textFormatter = (cell) => {
    return (
        <div >
            {cell &&
            <span className="custom-report" onClick={() => toraPortalApiService.downloadFile(cell.downloadURL)}>
                <i style={{fontSize: '10px', paddingRight: '8px'}} className="now-ui-icons arrows-1_cloud-download-93"> </i>
                {cell.label}
            </span>
            }
        </div>
    )
};

class ExecutionsReportsTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userData: this.props.data,
            loading: false,
            editedKeys : [],
            saveButtonDisable: 'true'
        };

        this.titleStyle = {
            'padding-right' : '8px'
        };
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.clientName !== this.props.clientName) {
            this.setState({ saveButtonDisable: 'true' });
        }
    };

    preparedColumnsConfig = () => {
        let { year, month } = this.props;
            return [
                {
                    dataField: 'date',
                    text: year + '.' + month,
                    editable: false,
                    headerClasses: 'min-w-10 user-table-header big-column',
                    classes: 'cursor-default'
                },

                {
                    dataField: 'executions',
                    text: 'Executions',
                    formatter: textFormatter,
                    editable: false,
                    headerClasses: 'min-w-10 user-table-header',
                },

                {
                    dataField: 'volumes',
                    text: 'Volumes',
                    formatter: textFormatter,
                    editable: false,
                    headerClasses: 'min-w-10 user-table-header',
                },

                {
                    dataField: 'consolidate',
                    text: 'Consolidate',
                    formatter: textFormatter,
                    editable: false,
                    headerClasses: 'min-w-10 user-table-header extra-big-column'
                },
            ];
    };

    render() {
        let {data} = this.props;

        return (
            <div className="dashboard-table-wrapper">
                <Loader
                    loading={this.state.loading} />

                <BootstrapTable
                    hover
                    condensed
                    keyField='id'
                    classes='table-responsive table-executions-info'
                    rowStyle={{ fontSize: '10px' }}
                    bordered={ false }
                    data={ data }
                    columns={ this.preparedColumnsConfig() }
                />
            </div>
        );
    }
}

export default ExecutionsReportsTable;
