import React from 'react'
import {
  Card, Container, Row, Col
} from 'reactstrap'

import { Redirect } from 'react-router-dom'

import LoginForm from './LoginForm.jsx'
import LoginTFAuth from '../TFAuth/LoginTFAuth.jsx'

import utils from '../../utils'

class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      rememberMe: false,
      isAuthenticated: false,
      isVerified: false
    }
  }

  renderLoginForm () {
    if (this.state.isAuthenticated === true) {
      return (
        <LoginTFAuth
          email = {this.state.email}
          password = {this.state.password}
          rememberMe = {this.state.rememberMe}
          onVerifiedListenerFn = { () => this.setState({ isVerified: true }) } />
      )
    } else {
      return (
        <LoginForm
          onUserAuthenticatedListenerFn = {(loginCredentials) => {
            this.setState({
              email: loginCredentials.email,
              password: loginCredentials.password,
              rememberMe: loginCredentials.rememberMe,
              isAuthenticated: true
            })
          }} />
      )
    }
  }

  render () {
    if (utils.getUserData()) {
      return <Redirect to={'/dashboard/resources'} />
    }

    return (
      <div className="login-page">
        <div className="full-page-content">
          <div className="justify-content-center">
            <Container>
              <Row className="justify-content-center">
                <Col lg={5} md={8} xs={12} className="max-width">
                  <Card className="card-form">
                    {this.renderLoginForm()}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    )
  }
}

export default LoginPage
