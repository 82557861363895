import React from 'react'
import PropTypes from 'prop-types'
import TableRow from './TableRow'
import { Row, Col } from 'reactstrap'

const TableBody = ({
  rows,
  customRowClass,
  expandableRows,
  expandedRowComponentFn,
  customColumnClass,
  color
}) => {
  if (rows == null || rows.length === 0) {
    return (<Row className>
      <Col xs={12}>
        <div className="body-row row no_data_message">No data at the moment here!</div>
      </Col>
    </Row>)
  }

  return (
    <tbody onClick={onTableBodyClick}>
      {
        rows.map(
          (rowData, index) => <TableRow
            row={rowData}
            customRowClass={customRowClass}
            customColumnClass={customColumnClass}
            isExpandable={expandableRows}
            expandedRowComponentFn={
              expandedRowComponentFn && expandedRowComponentFn.bind(this, index, rowData)
            }
            id={index}
            key={index}
          />
        )
      }
    </tbody>)
}

const onTableBodyClick = (event) => {
  if (event.target.children.length > 0 && event.target.children[0].children.length > 0) {
    const element = event.target.children[0].children[0].children[0]
    element.click()
  }
}

TableBody.propTypes = {
  rows: PropTypes.array.isRequired,

  expandableRows: PropTypes.bool,
  customRowClass: PropTypes.string,
  customColumnClass: PropTypes.string
}

export default TableBody
