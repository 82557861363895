const accountCapabilitiesColumns = [
    { field: 'account', title: 'Account' },
    { field: 'product', title: 'Product' },
    { field: 'type', title: 'Type' },
    { field: 'exchange', title: 'Exchange' },
    { field: 'user', title: 'User' },
    { field: 'broker', title: 'Broker' }
];

const brokerCapabilitiesColumns = [
    { field: 'broker', title: 'Broker' },
    { field: 'flow', title: 'Flow' },
    { field: 'flag', title: 'Flag' },
    { field: 'product', title: 'Product' },
    { field: 'type', title: 'Type' },
    { field: 'exchange', title: 'Exchange' },
    { field: 'user', title: 'User' }
]

const iatbCapabilitiesColumns = [
    { field: 'brokerAccount', title: 'Account' },
    { field: 'internalAccount', title: 'Internal Account' },
    { field: 'tradebook', title: 'Tradebook' },
    { field: 'product', title: 'Product' },
    { field: 'type', title: 'Type' },
    { field: 'exchange', title: 'Exchange' },
    { field: 'user', title: 'User' }
]

const marketCapabilitiesColumns = [
    { field: 'product', title: 'Product' },
    { field: 'type', title: 'Type' },
    { field: 'exchange', title: 'Exchange' },
    { field: 'user', title: 'User' }
]

export const columnsMap = {
    accountCapabilities: accountCapabilitiesColumns,
    brokerCapabilities: brokerCapabilitiesColumns,
    iatbCapabilities: iatbCapabilitiesColumns,
    marketCapabilities: marketCapabilitiesColumns
}

export const tableNames = ['accountCapabilities', 'brokerCapabilities', 'marketCapabilities', 'iatbCapabilities']

export const getTableFiltersKey = (tableName) => {
    return `${tableName}Filters`;
}
