import React from 'react'
import Iframe from 'react-iframe'

class Tools extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      environment: 'TTS',
      environmentsUrls: {
        TTS: '/dashboard/environments/tts/tts',
        EVO: '/dashboard/environments/evo/evo',
        SMBC: '/dashboard/environments/smbc/smbc',
        US: '/dashboard/environments/us/us',
        LDN: '/dashboard/environments/ldn/ldn',
        APAC:'/dashboard/environments/apac/apac',
        EMEA:'/dashboard/environments/emea/emea',
        AMER:'/dashboard/environments/amer/amer',
      }
    }

    this.environmentClicked = this.environmentClicked.bind(this)
  }

  environmentClicked (event) {
    this.setState({ environment: event.target.innerText })
  }

  render () {
    return (
      <div className='environmentAndIframeWrapper'>
        <div className='environmentsWrapper'>
          <h5 className='environmentsText'>Environment</h5>
          <ul className='environments'>
            <li className={this.state.environment === 'TTS' ? 'liActive' : ''} onClick={this.environmentClicked}>TTS</li>
            <li className={this.state.environment === 'EVO' ? 'liActive' : ''} onClick={this.environmentClicked}>EVO</li>
            <li className={this.state.environment === 'SMBC' ? 'liActive' : ''} onClick={this.environmentClicked}>SMBC</li>
            <li className={this.state.environment === 'US' ? 'liActive' : ''} onClick={this.environmentClicked}>US</li>
            <li className={this.state.environment === 'LDN' ? 'liActive' : ''} onClick={this.environmentClicked}>LDN</li>
            <li className={this.state.environment === 'APAC' ? 'liActive' : ''} onClick={this.environmentClicked}>APAC</li>
            <li className={this.state.environment === 'EMEA' ? 'liActive' : ''} onClick={this.environmentClicked}>EMEA</li>
            <li className={this.state.environment === 'AMER' ? 'liActive' : ''} onClick={this.environmentClicked}>AMER</li>
          </ul>
        </div>
        <div className='iframeWrapper'>
          {
            this.state.environment !== ''
              ? <Iframe url={this.state.environmentsUrls[this.state.environment]}
                width='80%'
                height='1400px'
                display='initial'
                position='relative'
                className='iframe'/>
              : ''
          }
        </div>
      </div>
    )
  }
}

export default Tools
