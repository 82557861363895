import React from "react";
import { Link, Redirect } from "react-router-dom";
import {
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from "reactstrap";

import utils from "../../utils";

import images from "../../assets/img";
import toraConfig from "../../config";

import { createBrowserHistory } from "history";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      userGuidesDropdownOpen: false,
      reportsDropdownOpen: false,
      color: "white",
      user: JSON.parse(localStorage.getItem("user")),
      activePath: "",
    };

    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.userGuidesDropdownToggle = this.userGuidesDropdownToggle.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  userGuidesDropdownToggle(e) {
    this.setState({
      userGuidesDropdownOpen: !this.state.userGuidesDropdownOpen,
    });
  }

  reportsDropdownToggle(e) {
    this.setState({ reportsDropdownOpen: !this.state.reportsDropdownOpen });
  }

  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.refs.sidebarToggle.classList.toggle("toggled");
  }

  componentDidMount() {
    const history = createBrowserHistory();
    this.setState({ activePath: history.location.pathname });
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ activePath: e.history.location.pathname });
    }

    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.refs.sidebarToggle.classList.toggle("toggled");
    }
  }

  render() {
    const { activePath } = this.state;

    if (this.state.user === null) {
      return <Redirect to={"/login"} />;
    }

    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={this.props.location.pathname.indexOf("full-screen-maps") !== -1 ? "white" : this.state.color}
        expand="lg"
        className={
          "bg-white " +
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "fixed-top"
            : "fixed-top " + (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <NavbarBrand href="/dashboard/resources">
              <div className="navbar-logo-container">
                <img className="logo-img" src={images.lsegToraLogo} alt="lseg tora logo" />
                <span className="logo-text">Portal</span>
              </div>
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </NavbarToggler>
          <Collapse isOpen={this.state.isOpen} navbar className="justify-content-end">
            <Nav navbar>
              {utils.canViewUserGuides() || utils.isUserAdmin() ? (
                <Dropdown
                  className={activePath === "/dashboard/user-guides" ? "active-item" : ""}
                  nav
                  isOpen={this.state.userGuidesDropdownOpen}
                  toggle={(e) => this.userGuidesDropdownToggle(e)}
                >
                  <DropdownToggle caret nav className="navbar-link-container">
                    <span className="navbar-link-text">User Guides</span>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <DropdownItem tag="a" href="/dashboard/user-guides/oems">
                      OEMS
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              ) : (
                ""
              )}
              {utils.isOnboardingCompleted() || utils.isUserAdmin() || utils.isApplicationApproved() ? (
                <NavItem
                  className={
                    activePath === "/dashboard/resources" || activePath === "/dashboard/home" ? "active-item" : ""
                  }
                >
                  <Link to="/dashboard/resources" className="nav-link navbar-link-container">
                    <img className="navbar-link-icon" src={images.resourcesIcon} alt="resources icon" />
                    <span className="navbar-link-text">Resources</span>
                  </Link>
                </NavItem>
              ) : (
                ""
              )}

              {/* Disable until backend implementation is ready */}
              {(utils.isOnboardingCompleted() &&
                (utils.canViewAuditFiles() ||
                  utils.canViewBorrowFiles() ||
                  utils.canViewExecutionsFiles() ||
                  utils.canViewLimitFiles())) ||
              utils.canViewTcaFiles() ||
              utils.canViewLegalLedgerFiles() ||
              utils.isUserAdmin() ? (
                <Dropdown
                  className={
                    activePath === "/reports/audit" ||
                    activePath === "/reports/executions" ||
                    activePath === "/reports/limit"
                      ? "active-dropdown-item"
                      : ""
                  }
                  nav
                  isOpen={this.state.reportsDropdownOpen}
                  toggle={(e) => this.reportsDropdownToggle(e)}
                >
                  <DropdownToggle caret nav className="navbar-link-container">
                    <img className="navbar-link-icon" src={images.reportsIcon} alt="reports icon" />
                    <span className="navbar-link-text">Reports</span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {(utils.canViewAuditFiles() || utils.isUserAdmin()) && (
                      <DropdownItem tag="a" href="/reports/audit">
                        AUDIT
                      </DropdownItem>
                    )}
                    {(utils.canViewExecutionsFiles() || utils.isUserAdmin()) && (
                      <DropdownItem tag="a" href="/reports/executions">
                        EXECUTIONS
                      </DropdownItem>
                    )}
                    {(utils.canViewLimitFiles() || utils.isUserAdmin()) && (
                      <DropdownItem tag="a" href="/reports/limit">
                        LIMIT
                      </DropdownItem>
                    )}
                    {(utils.canViewBorrowFiles() || utils.isUserAdmin()) && (
                      <DropdownItem tag="a" href="/reports/borrow">
                        BORROW
                      </DropdownItem>
                    )}
                    {(utils.canViewTcaFiles() || utils.isUserAdmin()) && (
                      <DropdownItem tag="a" href="/reports/tca">
                        TCA
                      </DropdownItem>
                    )}
                    {(utils.canViewLegalLedgerFiles() || utils.isUserAdmin()) && (
                      <DropdownItem tag="a" href="/reports/legal-ledger">
                        LEGAL LEDGER
                      </DropdownItem>
                    )}
                    {(utils.canViewCapabilities() || utils.isUserAdmin()) && (
                      <DropdownItem tag="a" href="/reports/client-setup">
                        CLIENT SETUP
                      </DropdownItem>
                    )}
                    {(utils.isUserAdmin()) && (
                        <DropdownItem tag="a" href="/reports/client-versions">
                          CLIENT VERSIONS
                        </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              ) : (
                ""
              )}

              {utils.isUserAdmin() ? (
                <NavItem className={activePath === "/dashboard/tools" ? "active-item" : ""}>
                  <Link to="/dashboard/tools" className="nav-link navbar-link-container">
                    <img className="navbar-link-icon" src={images.toolsIcon} alt="reports icon" />
                    <span className="navbar-link-text">Tools</span>
                  </Link>
                </NavItem>
              ) : (
                ""
              )}

              <Dropdown
                className={
                  activePath === "/client-info" || activePath === "/dashboard/settings" ? "active-dropdown-item" : ""
                }
                nav
                isOpen={this.state.dropdownOpen}
                toggle={(e) => this.dropdownToggle(e)}
              >
                <DropdownToggle caret nav className="navbar-link-user-container">
                  <img className="navbar-link-user-icon" src={images.userIcon} alt="user icon" />
                  <span className="navbar-link-user-text">{this.state.user.firstName} {this.state.user.lastName}</span>
                  <p>
                    <span className="d-lg-none d-md-block">User Menu</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  {utils.isUserAdmin() && (
                    <DropdownItem tag="a" href="/user-guide">
                      User Guide
                    </DropdownItem>
                  )}
                  {utils.isUserAdmin() && (
                    <DropdownItem tag="a" href={toraConfig.TORA_ADMIN_PORTAL_LINK}>
                      LSEG TORA Portal Admin Panel
                    </DropdownItem>
                  )}
                  <DropdownItem tag="a" href="/dashboard/settings">
                    Settings
                  </DropdownItem>
                  <DropdownItem tag="a" onClick={utils.logoutUser}>
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Header;
