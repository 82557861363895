import apiConfig from './api-config'

export default {
  ...apiConfig,
  ENV: 'DEVELOPMENT',
  GET_IN_TOUCH_FORM_RETURN_URL: 'http://portal.caspian.tech/get-in-touch',
  GET_IN_TOUCH_SALESFORCE_URL: 'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8',
  SHOW_SSO_LOGIN: true,
  SHOW_RESOURCE_VERSION: true
}
