import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import {Footer, Header} from '../../components'

import dashboardRoutes, {mapDashRoutesPathToName} from '../../routes/dashboard.jsx'
import utils from '../../utils'
import toraPortalApiService from "../../services/ToraPortalApiService";

class Dashboard extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateActualPath()
  }

  componentDidMount() {
    this.updateActualPath()
  }

  updateActualPath() {
    console.log(window.location.pathname)
    if (window.location && window.location.pathname in mapDashRoutesPathToName) {
      toraPortalApiService.postWebPageAccessed(mapDashRoutesPathToName[window.location.pathname],
                                               window.location.href)
    }
  }

  render() {
    if (!utils.userIsAuthenticated()) {
      return <Redirect to={'/login'}/>
    }
    if (!utils.isUserAdmin()) {
      if (this.props.location.pathname !== '/dashboard/settings' && this.props.location.pathname
          !== '/dashboard/user-guides/oms') {
        if (!utils.isOnboardingCompleted()) {
          if ((utils.isKYCCompleted() || utils.isSubmittedInitialData() || utils.isApplicationApproved())) {
            if (utils.isUserAccountManager()) {
              return <Redirect to={'/client-info'}/>
            }
          } else {
            if (utils.isAwaitingKYC() || utils.isKYCPending()) {
              return <Redirect to={'/kyc'}/>
            }
          }
        }
      }
    }

    return (
      <div className="wrapper dashboard-layout">
        <div className="main-panel" ref="mainPanel">
          {
            this.props.location.pathname.includes('/dashboard/environments')
              ? ''
              : <Header {...this.props}/>
          }
          <Switch>
            {
              dashboardRoutes.map((prop, key) => {
                if (prop.collapse) {
                  return prop.views.map((prop2, key2) => {
                    return (
                      <Route path={prop2.path} component={prop2.component} key={key2}/>
                    )
                  })
                }
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key}/>
                  )
                }
                return (
                  <Route path={prop.path} component={prop.component} key={key}/>
                )
              })
            }
          </Switch>
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf('full-screen-maps') !== -1 ||
                        this.props.location.pathname.includes('/dashboard/environments')
              ? null
              : <Footer fluid/>
          }
        </div>
      </div>
    )
  }
}

export default Dashboard
