import React, {useEffect, useRef, useState} from "react";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import {MenuItem, Select} from "@material-ui/core";
import {withStyles} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {columnsMap, getTableFiltersKey} from "./capabilitiesUtils";

const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
    }
}))(ToggleButtonGroup);

function CapabilitiesTable(props) {
    const {data, tableName, consolidateUser, consolidateExchange, onConsolidate} = props;
    const columns = columnsMap[tableName];
    const tableFiltersKey = getTableFiltersKey(tableName);
    const [gridApi, setGridApi] = useState(null);
    const [pageSize, setPageSize] = useState(25);
    const consolidateUserChanged = useRef(false);
    const consolidateExchangeChanged = useRef(false);

    let consolidateButtonsFormats = [];
    if (consolidateUser) {
        consolidateButtonsFormats.push('user');
    }
    if (consolidateExchange) {
        consolidateButtonsFormats.push('exchange');
    }
    const [formats, setFormats] = useState(consolidateButtonsFormats);

    useEffect(() => {
        restoreFilters();
    }, [data])

    const handleFormat = (event, newFormats) => {
        setFormats(newFormats);
        let consolidateUser = false;
        let consolidateExchange = false;
        const initialConsolidateUser = props.consolidateUser;
        const initialConsolidateExchange = props.consolidateExchange;

        for (const selected of newFormats) {
            if (selected === 'user') {
                consolidateUser = true;
            }
            if (selected === 'exchange') {
                consolidateExchange = true;
            }
        }

        consolidateUserChanged.current = consolidateUser !== initialConsolidateUser;
        consolidateExchangeChanged.current = consolidateExchange !== initialConsolidateExchange;

        onConsolidate(consolidateUser, consolidateExchange);
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const onFirstDataRendered = (params) => {
        params.api.sizeColumnsToFit();
    };

    const restoreFilters = () => {
        if (gridApi) {
            gridApi.setFilterModel(JSON.parse(localStorage.getItem(tableFiltersKey)))
        }
    }

    function saveActualFilterInStorage() {
        const currentFilters = JSON.stringify(gridApi.getFilterModel())
        localStorage.setItem(tableFiltersKey, currentFilters)
    }

    const onFilterModified = () => {
        if (consolidateUserChanged.current || consolidateExchangeChanged.current) {
            consolidateUserChanged.current = false;
            consolidateExchangeChanged.current = false;
            const filterKey = JSON.parse(localStorage.getItem(tableFiltersKey))

            if (!filterKey) {
                saveActualFilterInStorage()
            }
            restoreFilters()

        } else {
            saveActualFilterInStorage()
        }
    }

    const onPageSizeChanged = (event) => {
        const newPageSize = Number(event.target.value)
        gridApi.paginationSetPageSize(newPageSize);
        setPageSize(newPageSize);
    }

    const renderPageSizeSelect = () => (
        <div className="select-page-size">
            <span>Page Size: </span>
            <Select
                labelId="demo-simple-select-label"
                id="page-size"
                value={pageSize}
                onChange={onPageSizeChanged}>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={75}>75</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={data ? data.length : 1000}>All</MenuItem>
            </Select>
            <StyledToggleButtonGroup
                size="small"
                value={formats}
                onChange={handleFormat}
                aria-label="text formatting"
            >
                <ToggleButton value="user">
                    consolidate user
                </ToggleButton>
                <ToggleButton value="exchange">
                    consolidate exchange
                </ToggleButton>
            </StyledToggleButtonGroup>
        </div>
    );

    const renderColumns = () => {
        return columns.map(column =>
            <AgGridColumn
                key={column.field}
                field={column.field}
                headerName={column.title}
                filterParams={{suppressAndOrCondition: true}}
                suppressMenu={true}
            />
        )
    }

    return (
        <div style={{width: '100%', height: '100%'}}>
            <div className="ag-theme-material grid-container">
                {renderPageSizeSelect()}
                <AgGridReact
                    onGridReady={onGridReady}
                    onFilterChanged={onFilterModified}
                    onFirstDataRendered={onFirstDataRendered}
                    rowData={data}
                    defaultColDef={{sortable: true, filter: true, floatingFilter: true, resizable: true}}
                    pagination={true}
                    paginationPageSize={25}>
                    {renderColumns()}
                </AgGridReact>
            </div>
        </div>
    )
}

export default CapabilitiesTable;
