import React from "react";
import _ from "lodash";
import jwt_decode from "jwt-decode";

import { UncontrolledTooltip } from "reactstrap";
import dateFormat from "dateformat";

const userIsAuthenticated = () => {
  const token = localStorage.getItem("token");
  if (token !== null) {
    const decoded = jwt_decode(token);
    if (decoded.exp > Date.now() / 1000) {
      return true;
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    }
  }
  return false;
};

const logoutIfExpired = () => {
  if (!userIsAuthenticated()) {
    logoutUser();
  }
};

const logoutUser = () => {
  let logoutUrl = localStorage.getItem("logoutUrl");
  if (!logoutUrl) {
    logoutUrl = "/login";
  }
  cleanAfterLogout();
  window.location.href = logoutUrl;
};

const cleanAfterLogout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("kyc_status");
  localStorage.removeItem("storedClientsList");
  localStorage.removeItem("inputFilter");
  localStorage.removeItem("logoutUrl");
  sessionStorage.removeItem("edit-kyc");
  sessionStorage.removeItem("activeClientName");
  sessionStorage.removeItem("sidebarStatus");
};

const isKYCCompleted = () => {
  const kycStatus = localStorage.getItem("kyc_status");
  return kycStatus === "KYC_COMPLETED";
};

const isKYCPending = () => {
  const kycStatus = localStorage.getItem("kyc_status");
  return kycStatus === "KYC_PENDING";
};

const isAwaitingKYC = () => {
  const kycStatus = localStorage.getItem("kyc_status");
  return kycStatus === "AWAITING_KYC";
};

const isOnboardingCompleted = () => {
  const kycStatus = localStorage.getItem("kyc_status");
  return kycStatus === "ONBOARDING_COMPLETED";
};

const isSubmittedInitialData = () => {
  const kycStatus = localStorage.getItem("kyc_status");
  return kycStatus === "SUBMITTED_INITIAL_DATA";
};

const isApplicationApproved = () => {
  const kycStatus = localStorage.getItem("kyc_status");
  return kycStatus === "APPLICATION_APPROVED";
};

const isCorporateUser = () => {
  return true;
  // kycStatus === 'COMPLETED'
};

const getUserData = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const isUserAccountManager = () => {
  if (getUserData() == null || getUserData().authorities == null) {
    return false;
  }
  return _.indexOf(getUserData().authorities, "ACCOUNT_HOLDER") >= 0;
};

const canViewOnboarding = () => {
  const userData = getUserData();
  return userData !== null && userData.canViewOnboarding;
};

const canViewAuditFiles = () => {
  const userData = getUserData();
  return userData !== null && userData.audit;
};

const canViewLimitFiles = () => {
  const userData = getUserData();
  return userData !== null && userData.limits;
};

const canViewBorrowFiles = () => {
  const userData = getUserData();
  return userData !== null && userData.borrow;
};

const canViewTcaFiles = () => {
  const userData = getUserData();
  return userData !== null && userData.tca;
};

const canViewLegalLedgerFiles = () => {
  const userData = getUserData();
  return userData !== null && userData.legalLedger;
};

const canViewUserGuides = () => {
  const userData = getUserData();
  return userData !== null && userData.userGuides;
};

const canViewCapabilities = () => {
  const userData = getUserData();
  return userData !== null && userData.capabilities;
};

const canViewExecutionsFiles = () => {
  const userData = getUserData();
  return userData !== null && userData.executions;
};

const isUserAdmin = () => {
  if (getUserData() == null || getUserData().authorities == null) {
    return false;
  }

  return _.indexOf(getUserData().authorities, "ROLE_ADMIN") >= 0;
};

const statusFormater = (cell, row) => {
  return (
    <span>
      <span id={"status-" + row.id}>{cell || "N/A"}</span>
      {row.ticketStatus !== "NONE" ? (
        <UncontrolledTooltip placement="top" target={"status-" + row.id}>
          Last Ticket Status: {row.ticketStatus}
        </UncontrolledTooltip>
      ) : (
        ""
      )}
    </span>
  );
};

const dateFormater = (cell, row) => {
  return dateFormat(cell, "mmmm dS, yyyy");
};

export const formatDateWithSeconds = (date) => {
  return dateFormat(date, "dd/mm/yyyy HH:MM:ss")
}

const reportsTypeFolderNameMap = {
  audit: "audit",
  limits: "limits",
  executions: "executions",
  borrow: "borrow",
  tca: "tcaReports",
  legalLedger: "legalledger",
};

const createRoutesNameMap = (routes) => {
  let mapPathToPageName = {};
  routes.forEach((item) => {
    if (item.name) {
      if (item.replaceUrlParameterWithValues) {
        let paths = [item.path];
        for (let parameterUrl in item.replaceUrlParameterWithValues) {
          let valuesToReplace = item.replaceUrlParameterWithValues[parameterUrl];
          let newPaths = [];
          paths.forEach((path) => {
            valuesToReplace.forEach((value) => {
              newPaths.push(path.replace(parameterUrl, value));
            });
          });

          paths = newPaths;
        }

        paths.forEach((path) => {
          mapPathToPageName[path] = item.name;
        });
      } else {
        mapPathToPageName[item.path] = item.name;
      }
    }
  });

  return mapPathToPageName;
};

export const mapColumns = (data, columnMapping, valueMapping) => {
  return data.map(row => {
    const renamedRow = {};
    Object.keys(row).forEach(key => {
      const newKey = columnMapping[key] || key;
      renamedRow[newKey] = '';
      if(valueMapping[key] && valueMapping[key][row[key]]) {
        renamedRow[newKey] = String(valueMapping[key][row[key]])
      } else {
        renamedRow[newKey] = String(row[key]);
      }
    });
    return renamedRow;
  });
};

export const applyFilters = (data, valueFilter) => {
  return data.filter(row => {
    for (const key in valueFilter) {
      if (valueFilter[key][row[key]]) {
        return false;
      }
    }
    return true;
  })
}


export default {
  userIsAuthenticated,
  isKYCCompleted,
  isKYCPending,
  isOnboardingCompleted,
  isAwaitingKYC,
  isSubmittedInitialData,
  isApplicationApproved,
  isCorporateUser,
  logoutUser,
  logoutIfExpired,
  cleanAfterLogout,
  getUserData,
  isUserAccountManager,
  canViewOnboarding,
  isUserAdmin,
  statusFormater,
  dateFormater,
  canViewAuditFiles,
  canViewExecutionsFiles,
  canViewLimitFiles,
  canViewBorrowFiles,
  canViewTcaFiles,
  canViewLegalLedgerFiles,
  canViewUserGuides,
  canViewCapabilities,
  reportsTypeFolderNameMap,
  createRoutesNameMap,
};
